import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './TimerComponent.scss';

const Timer = ({ airTime }) => {
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  
  const deadline = airTime; //"December, 31, 2022";
  const pageRefresh = () => {
    window.location.reload();
  } 
 const getTime = () => {
    const time = deadline - Date.now();    
    if (time <= 0) {
      pageRefresh();
      return;
    }
    setHours(Math.floor(((time / 1000 / 3600) % 60)))
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  React.useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timer" role="timer">
          {hours > 0 ? 
          <>
            {hours < 10? "0" + hours: hours}
            <span className='dots'>:</span>
          </>: ''}
          {minutes < 10 ? "0" + minutes : minutes}
       
      <span className='dots'>:</span>
    
         {seconds < 10 ? "0" + seconds : seconds}
     
    </div>
  );
};

export default withStyles(styles)(Timer); 
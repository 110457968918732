import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
// import PlaybackContainer from '@airtel-feature/playback/PlaybackContainer';
import PlaybackContainer from '@airtel-feature/playback/PlaybackContainer';
import { PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { SubscriptionUtil } from '@airtel-tv/utils/SubscriptionUtil';
import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import {
    PLAYER_CONFIG,
} from '@airtel-tv/constants/PlayerConsts';
import NextEpisodeFactory from '../../content-details/factory/NextEpisodeFactory';
import { hidePlayback, showBannerOnPlay } from '../../../../web/src/modules/notify/NotifyActions';
// import { hidePlayback } from '../../notify/NotifyActions';
import {
    cleanNextTab, contentDetailsFetchAction, episodeTabsFetchAction, fetchSeasonNextEpisodeDataAction,
} from '../../content-details/actions/ContentDetailsAction';
// import withDeviceUtil from '../../shared/hoc/WithDeviceUtil';
import TrailerSubscribeCTA from '../component/TrailerSubscribeCTA/TrailerSubscribeCTA';

class PlayerContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            contentDetails: {},
            nextEpisodeDetails: {},
            playableId: '',
        };
        this.isWeb = DeviceTypeUtil.isWeb();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            contentDetails,
            nextEpisodeDetails,
            playableId,
            cpDetailsById,
            subscriptionDataById,
            contentCollection,
        } = nextProps;

        const cpId = lodashGet(contentDetails, 'cpId', '');
        const channelId = SubscriptionUtil.getChannelId({
            cpDetailsById,
            cpId,
        });
        const channelDetails = lodashGet(cpDetailsById, `[${channelId}]`, null);
        const subscriptionDetails = lodashGet(subscriptionDataById, `[${channelId}]`, null);
        const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
        const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);

        if (
            !prevState
            || prevState.contentDetails !== contentDetails
            || prevState.nextEpisodeDetails !== nextEpisodeDetails
            || prevState.playableId !== playableId
            || prevState.contentCollection !== contentCollection
        ) {
            return {
                contentDetails,
                nextEpisodeDetails,
                playableId,
                isSubscribed,
                contentCollection,
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    componentDidMount() {
        const {
            showBannerOnPlayDispatch, location: { search = '' } = {},
        } = this.props;

        if (!(search.includes('showPlayback') || search.includes('showPlaybackTrailer'))) {
            // On Page refresh the qp get removed but playback still continues, this condition resolves that issue
            showBannerOnPlayDispatch();
        }

        // history.listen(() => {
        //     // On browser back button click
        //     if (location.search.includes('showPlayback')) {
        //         hidePlaybackDispatch();
        //     }
        //     if (!playingOnBanner) {
        //         showBannerOnPlayDispatch();
        //     }
        // });
        // if (this.props.trailerRefId) {
        //     const quickViewTrailer = document.getElementById("quick-view-trailer");
        //     // fullimgContainer?.classList.add('d-none');
        //     quickViewTrailer?.classList.remove('opacity-0');
        //     quickViewTrailer?.classList.add('opacity-1');
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.seasonId && prevProps.seasonId !== this.props.seasonId) {
            this.props.cleanNextTabDispatch();
            this.props.fetchSeasonNextEpisodeDataActionDispatch({
                seasonId: this.props.seasonId,
                episodeId: this.state.contentCollection?.playableId,
            });
        }
        if (!this.isWeb) {
            const { canFetchMoreTabs } = this.props;
            const { episodeOrder } = this.state.contentCollection;
            if (canFetchMoreTabs && prevProps.canFetchMoreTabs !== canFetchMoreTabs && episodeOrder && episodeOrder === 1) {
                // dispatch the action here
                this.props.fetchTabsActionDispatch({
                    tab: this.props.nextTab,
                    seasonId: this.props.seasonId,
                });
            }
        }
    }

    componentWillUnmount() {
        const {
            location, hidePlaybackDispatch, playingOnBanner, showBannerOnPlayDispatch,
        } = this.props;
        if (location.search.includes('showPlayback')) {
            hidePlaybackDispatch();
        }
        if (!playingOnBanner) {
            showBannerOnPlayDispatch();
        }
    }

    render() {
        const {
            contentCollection, contentId, contentDetailsFetchActionDispatch, deviceUtil, shouldAddInCW, trailerRefId = '',
            isCDP = false,
            bufferingGoal = PLAYER_CONFIG.GOAL_BUFFER_LENGTH, explicitTrailerRefId = '',
            location: { search = '' } = {}, pageId,
        } = this.props;
        const {
            contentDetails = {}, nextEpisodeDetails, playableId, isSubscribed,
        } = this.state;
        const urlParams = new URLSearchParams(search);
        const lastWatchedPositionTrailer = urlParams.get('lastWatchedPositionTrailer');
        const cpId = lodashGet(contentDetails, 'cpId', '');
        const { isIOS } = deviceUtil;
        const { contentAutoplay = false, appInAppRedirection = false } = contentDetails;
        let playableDetails;
        let isTrailer = false;
        const playAsTrailer = contentDetails?.playAsTrailer || false;
        if (trailerRefId && contentDetails) {
            playableDetails = contentDetails;
            isTrailer = true;
        }
        // if (trailerRefId || location.search.includes('showPlaybackTrailer')) {
        //     playableDetails = contentCollection[contentId];
        //     isTrailer = true;
        // }
        else if ([
            PROGRAM_TYPES.TVSHOW,
            PROGRAM_TYPES.MOVIE,
            PROGRAM_TYPES.VIDEO,
        ].includes(contentDetails.programType)) {
            playableDetails = contentCollection[playableId];
            if (contentDetails.programType === PROGRAM_TYPES.TVSHOW && playableId && !contentCollection[playableId]) {
                contentDetailsFetchActionDispatch(playableId);
            }
        }
        // else if (contentDetails.programType === PROGRAM_TYPES.EPISODE || contentDetails.programType === PROGRAM_TYPES.TRAILER) {
        else {
            playableDetails = contentDetails;
        }
        console.log('playercontainer rerender');
        return (
            <div className={contentAutoplay ? 'under-banner-player autoplay-bottomup' : 'under-banner-player'}>
                {playableDetails && (
                    <PlaybackContainer
                        contentDetails={playableDetails}
                        playableId={playableId}
                        nextEpisodeDetails={nextEpisodeDetails}
                        isTrailer={isTrailer}
                        trailerRefId={trailerRefId}
                        isSubscribed={isSubscribed}
                        shouldAddInCW={shouldAddInCW}
                        playAsTrailer={isTrailer}
                        isCDP={isCDP}
                        explicitTrailerRefId={explicitTrailerRefId}
                        bufferingGoal={bufferingGoal}
                        lastWatchedPositionTrailer={lastWatchedPositionTrailer}
                        pageId={pageId}
                        contentAutoplay={contentAutoplay}
                    />
                )}
                {/* {
                    playableDetails && isTrailer && this.isWeb && (
                        <TrailerSubscribeCTA
                            cpId={cpId}
                            isIOS={isIOS}
                        />
                    )
                } */}
            </div>
        );
    }
}

PlayerContainer.defaultProps = {
    playableId: '',
    nextEpisodeDetails: {},
    shouldAddInCW: true,
    shouldPlaybackRestart: false,
    togglePlaybackRestartFlag: () => {},
    playingOnBanner: false,
};

PlayerContainer.propTypes = {
    contentDetails: PropTypes.any.isRequired,
    hidePlaybackDispatch: PropTypes.func.isRequired,
    playableId: PropTypes.string,
    nextEpisodeDetails: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    contentCollection: PropTypes.object.isRequired,
    contentId: PropTypes.string.isRequired,
    contentDetailsFetchActionDispatch: PropTypes.func.isRequired,
    cpDetailsById: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    subscriptionDataById: PropTypes.object.isRequired,
    shouldAddInCW: PropTypes.bool,
    playingOnBanner: PropTypes.bool,
    showBannerOnPlayDispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
    const { playableId } = props;
    const {
        contentDetails: contentCollection,
        appConfig,
        userConfig,
        notify: {
            playingOnBanner,
        },
    } = state;
    const { contentDetails } = props;
    const { previousTab, currentSeasonId } = contentCollection;
    let { currentTab, nextTab } = contentCollection;
    if (!DeviceTypeUtil.isWeb() && currentSeasonId) {
        nextTab = contentCollection[currentSeasonId].nextTab;
        currentTab = contentCollection[currentSeasonId].currentTab;
    }
    const seasonId = contentCollection[contentCollection?.playableId]?.seasonId;
    const seasonTabs = contentCollection[seasonId]?.tabs;
    let episodesDetails = DeviceTypeUtil.isWeb() ? contentCollection[currentTab] || [] : contentCollection[seasonId]?.episodeRefs || [];
    if (nextTab && contentCollection[nextTab]) {
        episodesDetails = [
            ...episodesDetails,
            ...contentCollection[nextTab],
        ];
    }
    if (previousTab && contentCollection[previousTab]) {
        episodesDetails = [
            ...contentCollection[previousTab],
            ...episodesDetails,
        ];
    }

    let nextEpisodeDetails = null;
    if (!props.trailerRefId && contentDetails) {
        nextEpisodeDetails = NextEpisodeFactory({
            contentDetails,
            contentCollection,
            episodesDetails,
            contentId: playableId,
        });
    }

    const playableContentIndex = episodesDetails.findIndex(episode => episode.refId === playableId);
    let canFetchMoreTabs = false;
    if ((playableContentIndex > -1) && currentTab && nextTab && (playableContentIndex === episodesDetails.length - 1)) {
        canFetchMoreTabs = true;
    }


    return {
        contentDetails,
        nextEpisodeDetails,
        contentCollection,
        cpDetailsById: appConfig.cpDetailsById,
        subscriptionDataById: userConfig.subscriptionDataById,
        seasonId,
        playingOnBanner,
        currentTab,
        seasonTabs,
        nextTab,
        canFetchMoreTabs,
    };
}

export default withDeviceUtil(withRouter(connect(mapStateToProps, {
    hidePlaybackDispatch: hidePlayback,
    contentDetailsFetchActionDispatch: contentDetailsFetchAction,
    fetchTabsActionDispatch: episodeTabsFetchAction,
    fetchSeasonNextEpisodeDataActionDispatch: fetchSeasonNextEpisodeDataAction,
    showBannerOnPlayDispatch: showBannerOnPlay,
    cleanNextTabDispatch: cleanNextTab,
})(PlayerContainer)));

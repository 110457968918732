/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import {
    calculateProgressPercentage, setAnalyticsMeta, setStitchId,
    getAmazonAppInAppMeta,
} from '@airtel-tv/utils/GlobalUtil';
import { withWatchlistDataHOC } from '@airtel-tv/lib/hoc/withWatchlistDataHOC';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import EventType from '@airtel-tv/analytics/EventType';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
// import lodashGet from 'lodash/get';
import { LOGIN_SOURCE, SUBSCRIPTION_SOURCE } from '@airtel-tv/constants/EventsConst';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
// import { SubscriptionUtil } from '@airtel-tv/utils/SubscriptionUtil';
import { AnalyticsButtonComponent, AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { ANALYTICS_ACTIONS, DEEPLINK_INGRESS_KEYS } from '@airtel-tv/constants';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import { playbackDetailsFetchAction, appInAppHandling } from '@airtel-feature/playback/actions/PlaybackActions';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import {
    ONEHUB_INGRESS_INTENT,
    CDP_CTA_TYPES,
} from '@airtel-tv/constants/GlobalConst';
import { showModalComponentAction, hideModalComponentAction } from '../../../../../web/src/modules/modal-popup/ModalPopupActions';
import { triggerLogin } from '../../../../../web/src/modules/auth/AuthAction';
import { contentDetailsFetchAction } from '../../../content-details/actions/ContentDetailsAction';
import { showPlayback } from '../../../../../web/src/modules/notify/NotifyActions';
import { MODAL_POPUP, PROGRAM_TYPES } from '../../../../../web/src/constants/AppConst';

class TileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addToWatchListEp: '',
            playEpisodeId: '',
        };
        this.clearTimeOutIds = {};
        this.episodeImageSettings = {
            320: 250,
            500: 250,
            1000: 450,
        };
        const { deviceUtil: { isMobile } } = props;

        this.isMobile = isMobile();
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     const {
    //         contentDetails, contentDetails: {
    //             contentId,
    //         }, subscriptionDataById, cpDetailsById,
    //         userContentProperties, userContentProperties: { oSeg = '' } = {}
    //     } = nextProps;
    //     const cpId = lodashGet(contentDetails[contentId], 'cpId', '');
    //     const channelId = SubscriptionUtil.getChannelId({
    //         cpDetailsById,
    //         cpId,
    //     });
    //     // const channelDetails = lodashGet(cpDetailsById, `[${channelId}]`, null);
    //     const subscriptionDetails = lodashGet(subscriptionDataById, `[${channelId}]`, null);
    //     // const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
    //     // const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);
    //     if (!prevState
    //         || subscriptionDetails !== prevState.subscriptionDetails) {
    //         return {
    //             subscriptionDetails,
    //         };
    //     }

    //     return null;
    // }


    componentDidUpdate(prevProps) {
        const { addToWatchListEp, playEpisodeId } = this.state;
        const {
            contentDetails, watchListToggleButtonClick, showPlaybackDispatch, history, location, deviceUtil,
            appInAppHandlingDispatch,
            amazonRedirectionConfig,
            playbackDetailsFetchDispatch,
            contentDetails: {
                contentId,
            } = {},
            isSubscribed,
        } = this.props;
        const { appInAppRedirection = false } = contentDetails?.[contentId] || {};

        if (contentDetails !== prevProps.contentDetails) {
            if (contentDetails[addToWatchListEp] && contentDetails[addToWatchListEp].id === addToWatchListEp) {
                watchListToggleButtonClick(contentDetails[addToWatchListEp]);
            }
            else if (contentDetails[playEpisodeId] && contentDetails[playEpisodeId].id === playEpisodeId) {
                const { free, cpId, gtiId } = contentDetails[playEpisodeId];
                const { currentTab } = contentDetails;
                if (!isSubscribed && !free && !deviceUtil.isScopedWebview()) {
                    // redirect to payment flow for unsubcribed and not free channels
                    const url = RoutingUtil.getOneHubPageCDP({
                        ingressIntent: ONEHUB_INGRESS_INTENT.CONTENT,
                        cpId,
                    });
                    history.push({
                        pathname: url,
                    });
                }
                else {
                    // play content
                    if (cpId === 'AMAZON_PRIME') {
                        // appinapp redirection specifically for amazonprime
                        this.appInAppMeta = getAmazonAppInAppMeta({
                            gtiId,
                            ...amazonRedirectionConfig,
                        });
                        appInAppHandlingDispatch({
                            appInAppMeta: this.appInAppMeta,
                            contentId: playEpisodeId,
                            cpId,
                            appInstalled: false,
                            gtiId,
                        });
                    }
                    else if (appInAppRedirection) {
                        playbackDetailsFetchDispatch({
                            contentId: playEpisodeId,
                            appInAppRedirection,
                        });
                    }
                    let params = {
                        showPlayback: true,
                    };
                    if (deviceUtil.isScopedWebview()) {
                        params = {
                            ...params,
                            viewType: 'scoped_webview',
                        };
                    }
                    if (currentTab) {
                        params.currentTab = currentTab;
                        history.replace({
                            pathname: `${location.pathname}`,
                            search: `${LocationUtil.objectToQueryParams({ currentTab })}`,
                        });
                    }
                    showPlaybackDispatch();
                    history.push({
                        pathname: location.pathname,
                        search: LocationUtil.objectToQueryParams(params),
                    });
                }
            }
        }
    }

    getImage = images => (images && images.LANDSCAPE_169);

    getDuration = (duration) => {
        let durInMin = 0;
        if (duration) {
            durInMin = Math.ceil(duration / 60);
        }
        return `${durInMin}m`;
    };

    isAddedToWatchList = (contentId) => {
        const { watchList } = this.props;
        return watchList[contentId] && Object.keys(watchList[contentId]).length > 0;
    };

    tileClicked = (episode) => {
        let {
            contentDetails,
            contentDetailsFetchActionDispatch,
            isAuthenticated,
            triggerLoginDispatch,
            contentDetails: {
                contentId,
            },
            sourceName,
            packageId,
            partnerStatus,
            history,
            isClaimPending,
        } = this.props;
        const episodId = episode.refId;
        contentDetails = contentDetails[contentId];
        const {
            tvShowName, title, cpId, id, programType,
        } = contentDetails;
        const analyticsSessionMeta = setAnalyticsMeta(id);
        const stitchId = setStitchId();
        const meta = {
            // ideally should be episode_click, but as per SOS sheet click mentioned
            // action: CDP_EVENT_CONST.EPISODE_CLICK,
            action: ANALYTICS_ACTIONS.CLICK,
            source_name: SOSUtil.getRouteSourceNameMappedFromCMS(sourceName),
            content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
            user_session_id: analyticsSessionMeta.userSessionId,
            ...(stitchId && { playback_stitch_key: stitchId }),
            package_id: packageId,
        };
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.PLAY_CLICK,
            });
        }
        else if (isClaimPending) {
            // redirect to cp activation page
            history.push({
                pathname: RoutingUtil.getCPActivationPage({ cpId }),
            });
        }
        else {
            contentDetailsEvent(meta, EventType.EPISODE_CLICK);
            contentDetailsFetchActionDispatch(episodId);
            this.setState({ playEpisodeId: episodId });
        }
    };


    addHoverClass = (episode) => {
        if (this.isMobile) {
            return;
        }
        const id = setTimeout(() => {
            const ele = document.getElementById(episode.refId);
            ele?.classList?.remove('zoom-out-tile');
            ele?.classList?.add('zoom-tile');
        }, 500);
        this.clearTimeOutIds[episode.refId] = id;
    };

    removeHoverClass = (episode) => {
        const id = this.clearTimeOutIds[episode.refId];
        if (id) {
            clearTimeout(id);
            delete this.clearTimeOutIds[episode.refId];
        }
        const ele = document.getElementById(episode.refId);
        ele?.classList?.remove('zoom-tile');
        ele?.classList?.add('zoom-out-tile');
    };

    onViewMore = (episode) => {
        const { showModalComponentActionDispatch } = this.props;
        if (this.isMobile) {
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.MORE_INFO_POPUP,
                overrideCrossButtonValue: true,
                payload: {
                    contentDetails: {
                        title: `Ep ${episode.episodeNumber} : ${episode.name}`,
                        shortDescription: episode.description,
                    },
                    showAudio: false,
                },

            });
        }
    };


    render() {
        const {
            episodes, continueWatchingData, contentDetails,
            freeTagEnabled = false, showModalComponentActionDispatch, hideModalComponentActionDispatch, isXppUser, maxAllowedPlaybackTime, userContentProperties, deepLinkConfig, pageId,
        } = this.props;
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const { LABEL_VIEW_MORE = 'More', FREE_TAG_TEXT = 'Free' } = LANGUAGE;
        return (
            <div className="cdp-tiles-wrapper">

                { episodes.map((episode) => {
                    const showFreeTag = freeTagEnabled && (episode?.free || false);
                    return (
                        <div
                            className={`cdp-tile ${calculateProgressPercentage({
                                continueWatchingData,
                                id: episode.refId,
                            }) ? ' progress' : ''}`}
                            id={episode.refId}
                            onMouseEnter={e => this.addHoverClass(episode)}
                            onMouseLeave={e => this.removeHoverClass(episode)}
                        >
                            <AnalyticsLinkComponent
                                onClick={(e) => {
                                    if (!ScopedWebviewUtil.canPlayPlayback(maxAllowedPlaybackTime, userContentProperties, episode)) {
                                        const contentId = contentDetails.contentId || '';
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showModalComponentActionDispatch({
                                            showModalValue: true,
                                            componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                                            overrideCrossButtonValue: true,
                                            payload: {
                                                contentDetails: {
                                                    id: episode.refId,
                                                    images: episode.images,
                                                    cpId: contentDetails[contentId]?.cpId,
                                                } || {},
                                                isSubscribed: isXppUser,
                                                pageId,
                                                meta: {
                                                    popup_id: 'download_hard_popup',
                                                    source_name: 'mweb_landing',
                                                    content_category: 'Content',
                                                },
                                                notNowBtnHandler: () => {
                                                    hideModalComponentActionDispatch({
                                                        showModalValue: false,
                                                        componentNameValue: null,
                                                        overrideCrossButtonValue: false,
                                                    });
                                                },
                                                downloadNowBtnHandler: () => {
                                                    ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                                                },
                                            },
                                        });
                                        return;
                                    }
                                    e.preventDefault();
                                    this.tileClicked(episode);
                                    e.stopPropagation();
                                }}
                            >

                                <div className="tile-image free-tag-tile-holder">
                                    <ThumborImage
                                        className={`img ${episode.refId}`}
                                        src={this.getImage(episode.images)}
                                        imageSettings={this.episodeImageSettings}
                                        alt={episode.name}
                                    />
                                    {showFreeTag ? <div className="free-tag-tile text-12 text-bold">{FREE_TAG_TEXT}</div> : null}
                                    <div className="center-play">
                                        <ThumborImage
                                            src="/static/play_ic.svg"
                                        />
                                    </div>
                                    { episode && (
                                        <div className="name-time-area">

                                            <span className="name">
                                                {showFreeTag && <span className="free-cdp-tag mobile-text-12 mobile-text-semi-bold">{FREE_TAG_TEXT}</span>}

                                                Ep
                                                {' '}
                                                {episode.episodeNumber }
                                                .
                                                {' '}
                                                { episode.name }
                                            </span>
                                            { episode.duration && <span className="time">{ this.getDuration(episode.duration) }</span> }
                                            {/* <button className="only-mobile-tile-btn" /> */}
                                        </div>
                                    ) }
                                </div>
                                {/* <div className="empty-box"></div> */}
                                <div className="tile-details">
                                    {episode.description
                                        ? (
                                            <div className="progress-area">
                                                { this.isMobile && episode.description.length > 140
                                                    ? (
                                                        <p
                                                            onClick={(e) => {
                                                                this.onViewMore(episode);
                                                                e.stopPropagation();
                                                            }}
                                                            className="episode-title-cdp"
                                                        >
                                                            {`${episode.description.slice(0, 120)}...`}
                                                            <AnalyticsButtonComponent
                                                                type="button"
                                                                className="cta cta--link cta--link--small cta--link--theme cta-link--vdo-more d-inline-block"
                                                            >
                                                                {`${LABEL_VIEW_MORE}`}
                                                            </AnalyticsButtonComponent>
                                                        </p>
                                                    )
                                                    : (
                                                        <p className="episode-title-cdp">
                                                            {episode.description}
                                                        </p>
                                                    )
                                                }


                                            </div>
                                        ) : null}

                                </div>
                                <div className="progress-bar-holder">
                                    { !!calculateProgressPercentage({
                                        continueWatchingData,
                                        id: episode.refId,
                                    }) && (
                                        <div className="progress-bar-grey">
                                            <div
                                                className="progress-bar-red"
                                                style={{
                                                    width: `${calculateProgressPercentage({
                                                        continueWatchingData,
                                                        id: episode.refId,
                                                    })}%`,
                                                }}
                                            />
                                        </div>
                                    ) }
                                    <div className="progress-cover" />
                                </div>

                            </AnalyticsLinkComponent>
                        </div>
                    );
                })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        syncContent: {
            watchList,
        },
        userConfig,
        userConfig: {
            partnerStatus = {},
            preferredPartnerPlans = null,
            userContentProperties, userContentProperties: { oSeg = '' } = {},
        },
        contentDetails,
        authConfig,
        appConfig: {
            freeTagEnabled = false,
            cpDetailsById = {},
            amazonRedirectionConfig = {},
            maxAllowedPlaybackTime,
            deepLinkConfig,

        } = {},
        layoutDetails: {
            contentDetail: {
                layoutPackages = [],
            } = {},
        } = {},
    } = state;
    let isSubscribed = false;
    let isClaimPending = false;
    layoutPackages.forEach((rail) => {
        const {
            format: {
                ty = '', action: { st: railCtaType = '' } = {},
            } = {},
        } = rail;
        if (ty === 'CONTENT_PLAY_RAIL') {
            if (railCtaType === CDP_CTA_TYPES.ACTIVATE_CTA) {
                isClaimPending = true;
            }
            else if (railCtaType === CDP_CTA_TYPES.PLAY_CTA) {
                isSubscribed = true;
            }
        }
    });

    const isXppUser = !!(oSeg || '').split(',').filter(item => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;

    return {
        watchList,
        contentDetails,
        isAuthenticated: authConfig.isAuthenticated,
        subscriptionDataById: userConfig.subscriptionDataById,
        cpDetailsById,
        preferredPartnerPlans,
        freeTagEnabled,
        amazonRedirectionConfig,
        partnerStatus,
        isXppUser,
        userContentProperties,
        maxAllowedPlaybackTime,
        deepLinkConfig,
        isSubscribed,
        isClaimPending,
    };
}

TileComponent.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    episodes: PropTypes.array,
    continueWatchingData: PropTypes.object.isRequired,
    watchListToggleButtonClick: PropTypes.func.isRequired,
    watchList: PropTypes.object.isRequired,
    contentDetails: PropTypes.object.isRequired,
    packageId: PropTypes.string,
    partnerStatus: PropTypes.object,
    appInAppHandlingDispatch: PropTypes.func.isRequired,
    amazonRedirectionConfig: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    deviceUtil: PropTypes.object.isRequired,
    freeTagEnabled: PropTypes.bool,
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    showPlaybackDispatch: PropTypes.func.isRequired,
};

TileComponent.defaultProps = {
    episodes: [],
    packageId: '',
    partnerStatus: {},
    freeTagEnabled: false,
};


export default withDeviceUtil(withRouter(connect(mapStateToProps, {
    showPlaybackDispatch: showPlayback,
    triggerLoginDispatch: triggerLogin,
    contentDetailsFetchActionDispatch: contentDetailsFetchAction,
    showModalComponentActionDispatch: showModalComponentAction,
    hideModalComponentActionDispatch: hideModalComponentAction,
    playbackDetailsFetchDispatch: playbackDetailsFetchAction,
    appInAppHandlingDispatch: appInAppHandling,
})(withWatchlistDataHOC(TileComponent))));

import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { ELEMENT_ID, ACTIVE_TILE_AFTER_TIMEOUT, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { withWatchlistDataHOC } from '@airtel-tv/lib/hoc/withWatchlistDataHOC';
import { findTileLimit, getLottieFiles } from '@airtel-tv/utils/GlobalUtil';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import {
    ANALYTICS_ACTIONS, ANALYTICS_ASSETS,
} from '@airtel-tv/constants/AnalyticsConst';
import {
    RAIL_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import { ThumborUtil } from '@airtel-tv/utils';
import ArrowIcon from '@airtel-tv/svg/ArrowIcon';
import CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';
import { getDistroFreeChannelTiles } from '@airtel-feature/layout/utils/LayoutUtil';
import { buildTile } from '@airtel-feature/layout/builders/TileBuilder';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
// import { tileScrollEvent } from '@airtel-tv/analytics/FunctionalEvents';
import PYWBackButton from '@airtel-tv/ui-lib/atoms/icons/components/PYWBackButton';
import RailEventHOC from '../../../../../web/src/modules/shared/hoc/RailEventHOC';
import { getTile } from '../../../../../web/src/modules/rail/factories/TileFactory';
import HorizontalLazyLoaderHOC from '../../../../../web/src/modules/shared/hoc/HorizontalLazyLoaderHOC';
import styles from './XstreamLayoutComponent.scss';

const titleLogoImageSettings = {
    300: 100,
    1000: 300,
};

const XstreamLayoutComponent = React.forwardRef((props, ref) => {
    const [
        animationData,
        handleAnimationData,
    ] = useState(null);
    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');
    const carouselElementRef = useRef();
    const {
        tiles, railTitle, railCtaActionUrl, railIndex, imageSettings, railId, packageId, pageId,
        webPageId,
        showMore,
        webPageId: { MORE_LIKE_THIS = '' },
        deviceUtil: { isMobile, isBot, isTabletViewPort },
        deviceUtil,
        onRailScroll,
        tileType,
        titleLogo,
        titleColor,
        actionTitle = '',
        showAll,
        highlighted_rail_details: { bgImg = '', subTitle = '' } = {},
        showPosition = false,
        titleLottie,
        formatedRailTitle = '',
        railSubtitle,
        seeAllArtWork = '',
        containerClass = '',
        slideBtnClass = '',
        sosAnalyticsMeta,
        titleType,
        maxTileTitleLines,
        preferredArtwork,
        style: styleProp,
        showGrid,
        isSearchEvent = false,
        isPYWRail = false,
        customRailSlideBtnClass = '',
        distroData = [],
        cpDetailsById,
        analyticsPageId = '',
        currentSource,
        prevSource,
        railType,
        isFreeChannelsRail,
        hideImageAltTag,
        hideTileBottomeDetails,
        customClassTileLevel,
        history,
        hasPrevPath,
        zionDisplay: { backgroundImageUrl, shouldShowHeaderView, backgroundImageUrlMWeb },
        customTileLimit,
        showRailScrollButtons = true,
        railCollapsible,
        collapsedComponent,
    } = props;

    const [
        updatedTiles,
        setUpdatedTiles,
    ] = useState(tiles);
    const [
        isRailCollapsed,
        setIsRailCollapsed,
    ] = useState(railCollapsible);

    useEffect(() => {
        setUpdatedTiles(tiles);
    }, [
        tiles,
    ]);

    const isMobileViewPort = () => {
        if (isPYWRail) {
            return isMobile() || isTabletViewPort();
        }
        return isMobile();
    };

    useEffect(() => {
        if (!tiles?.length && distroData?.length && isFreeChannelsRail) {
            const liveTvTiles = getDistroFreeChannelTiles({
                cpId: 'DISTROTV',
                distroData,
                buildTile,
                railType: RAIL_TYPES.X_FREE_CHANNELS,
                // RAIL_CTA_LIST,
                preferredArtwork: 'LOGO_HD',
                explicitRailCtaText: 'More',
                more: true,
                cpDetailsById,
                hideImageAltTag,
                hideTileBottomeDetails,
                customClassTileLevel,
            });
            if (liveTvTiles?.length) {
                setUpdatedTiles(liveTvTiles);
            }
        }
    }, [
        tiles,
        distroData,
        tileType,
    ]);

    useEffect(() => {
        if (titleLottie) {
            const lottieData = getLottieFiles(titleLottie);
            lottieData.then((res) => {
                handleAnimationData(res);
            });
        }
    }, [
        titleLottie,
    ]);

    const railRef = useRef();
    const wheelingTimerRef = useRef(null);
    const scrollingTimerRef = useRef(null);
    const pywTimerRef = useRef(null);
    const windowWidth = deviceUtil.getWindowWidth();
    const tileLimit = customTileLimit || findTileLimit({
        windowWidth,
        tileType,
    });
    let lazyTiles = [];
    const [
        indexOfRail,
        setRailIndex,
    ] = useState(-1);

    const [
        disableTileNavigation,
        setDisableTileNavigation,
    ] = useState(false);

    // const tileScrollAnalytics = ({ endTileIndex = 0, scrollType = 'vertical' }) => {
    //     const startIndex = indexOfRail < 0 ? 0 : indexOfRail;
    //     const startTile = updatedTiles[startIndex] || {};
    //     const endTile = updatedTiles[endTileIndex] || {};
    //     const { id: id1, contentDetails: { id: contentId1 } = {}, tileType: tileType1 = tileType } = startTile;
    //     const { id: id2, contentDetails: { id: contentId2 } = {}, tileType: tileType2 = tileType } = endTile;
    //     const meta = {
    //         source_name: currentSource,
    //         source_page: prevSource,
    //         rail_id: railId,
    //         rail_position: railIndex,
    //         rail_type: `Rail ${railType}`,
    //         package_id: packageId,
    //         tile_meta_list: `${id1}.${contentId1}.${startIndex}.${tileType1}
    //              | ${id2}.${contentId2}.${endTileIndex}.${tileType2}`,
    //         scroll_start_position: startIndex,
    //         scroll_end_position: endTileIndex,
    //         scroll_type: scrollType,
    //         manual_scroll: true,
    //     };
    //     tileScrollEvent(meta);
    // };

    const computePrevTileCenterDist = (prevTile) => {
        // nextTile - prev tile to show on screen
        const activeTilePosition = indexOfRail < 0 ? 0 : indexOfRail;
        const containerCenter = window?.innerWidth / 2;
        const prevTileRect = prevTile?.getBoundingClientRect();
        const scaledMargin = activeTilePosition === 1 ? 0 : ((7.5 / 100) * window?.innerWidth);
        const prevTileCenter = (prevTileRect?.left + (prevTileRect?.width / 2)) + scaledMargin;
        const distance = prevTileCenter - containerCenter; // generally computes negative Distance
        return distance;
    };

    const computeNextTileCenterDistance = (nextTile) => {
        // nextTile - tile to show next on screen
        const activeTilePosition = indexOfRail < 0 ? 0 : indexOfRail; // since default indexOfrail = -1
        const containerCenter = window?.innerWidth / 2;
        const nextTileRect = nextTile?.getBoundingClientRect();
        const scaledMargin = activeTilePosition === 0 ? 0 : ((7.5 / 100) * window?.innerWidth);
        const nextTileCenter = (nextTileRect?.left + (nextTileRect?.width / 2)) - scaledMargin;
        const distance = nextTileCenter - containerCenter; // generally computes positive distance, for wheel scroll might give negative
        return distance;
    };

    // const computeScrollPositionForPyw = (event) => {
    //     let tileIndex = 0;
    //     let closestTile = null;
    //     let minDistance = Infinity;
    //     const tilesCollection = carouselElementRef?.current?.children;
    //     for (let index = 0; index < tilesCollection?.length; index++) {
    //         const tile = tilesCollection[index];
    //         // previous
    //         if (event && event.deltaX < 0) {
    //             const distance = computePrevTileCenterDist(tile, index);
    //             if (distance < 0 && Math.abs(distance) < Math.abs(minDistance)) {
    //                 minDistance = distance;
    //                 closestTile = tile;
    //                 tileIndex = index;
    //             }
    //         }
    //         // next
    //         else if (event && event.deltaX > 0) {
    //             const distance = computeNextTileCenterDistance(tile, index);
    //             if ((distance > 0 && minDistance < 0) || Math.abs(distance) < Math.abs(minDistance)) {
    //                 minDistance = distance;
    //                 closestTile = tile;
    //                 tileIndex = index;
    //             }
    //         }
    //     }

    //     if (closestTile) {
    //         carouselElementRef.current.scrollLeft += minDistance;
    //     }
    //     return tileIndex;
    // };

    // const handleWheelScroll = (event) => {
    //     // horizontal scroll only
    //     if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
    //         window.clearTimeout(wheelingTimerRef?.current);
    //         wheelingTimerRef.current = setTimeout(() => {
    //             const tileIndex = computeScrollPositionForPyw(event);
    //             setRailIndex(tileIndex);
    //         }, ACTIVE_TILE_AFTER_TIMEOUT);
    //     }
    // };

    const pywPrevious = () => {
        const tilesCollection = carouselElementRef?.current?.children;
        if (tilesCollection && tilesCollection.length && indexOfRail > 0 && indexOfRail < tilesCollection.length) {
            const prevTileIndex = indexOfRail - 1;
            const prevTile = tilesCollection[prevTileIndex];
            const distance = computePrevTileCenterDist(prevTile);
            carouselElementRef.current.scrollLeft += distance;
            setRailIndex(indexOfRail - 1);
        }
    };

    const pywNext = () => {
        const tilesCollection = carouselElementRef?.current?.children;
        if (indexOfRail < tilesCollection.length - 1) {
            const nextTileIndex = indexOfRail < 0 ? 1 : indexOfRail + 1;
            const nextTile = tilesCollection[nextTileIndex];
            const distance = computeNextTileCenterDistance(nextTile);
            carouselElementRef.current.scrollLeft += distance;
            clearTimeout(pywTimerRef.current);
            setRailIndex(nextTileIndex);
        }
    };

    const getTopVisibleTileIndex = () => {
        const tilesCollection = carouselElementRef?.current?.children;
        let tileIndex = 0;
        let topVideoPosition = Infinity;
        for (let index = 0; index < tilesCollection?.length; index++) {
            const tile = tilesCollection[index];
            const tileRect = tile?.getBoundingClientRect();
            if (tileRect.top > 0 && tileRect.top < topVideoPosition && tileRect.bottom > 0 && tileRect.bottom < window?.innerHeight) {
                topVideoPosition = tileRect.top;
                tileIndex = index;
            }
        }
        return tileIndex;
    };

    const moreAnalyticsMeta = {
        rail_id: railId,
        // action: 'more_click',
        // source_name: SOSUtil.getRouteSourceNameMappedFromCMS(getCurrentWindowSourceName()),
        action: ANALYTICS_ACTIONS.RAIL_CTA_CLICK,
        asset_name: actionTitle || ANALYTICS_ASSETS.SEE_ALL,
        package_id: packageId,
        pageId: analyticsPageId,
        railPosition: railIndex,
        rail_type: railType,
        source_name: currentSource,
        source_page: prevSource,
        rail_title: railTitle,
    };
    const tileElements = updatedTiles.map((tile, i) => {
        const tileProps = {
            key: `${tile.id}-${i}`,
            ...tile,
            railCtaActionUrl,
            railPosition: railIndex,
            tilePosition: i,
            railId,
            imageSettings,
            pageId,
            shouldLazyLoad: i > tileLimit,
            railTitle,
            carouselElementRef,
            railRef,
            showPosition,
            hoveredTile,
            setHoveredTile,
            sosAnalyticsMeta,
            packageId,
            titleType,
            maxTileTitleLines,
            preferredArtwork,
            style: styleProp,
            showGrid,
            isSearchEvent,
            activeTilePosition: indexOfRail < 0 ? 0 : indexOfRail,
            setActiveTilePosition: setRailIndex,
            isFreeChannelsRail,
            hideImageAltTag,
            hideTileBottomeDetails,
            customClassTileLevel,
            analyticsPageId,
            setDisableTileNavigation,
            ...(isPYWRail && !isMobileViewPort() ? { videoEndedCallback: pywNext } : {}),
        };

        const tileElement = getTile({
            tileProps,
        });

        return tileElement;
    });
    const key = `${railId}-${railIndex}`;
    lazyTiles = (
        <HorizontalLazyLoaderHOC
            railKey={key}
            tileElements={tileElements}
            tileLimit={tileLimit}
        />
    );

    // useEffect(() => {
    //     const {
    //         location,
    //     } = props;

    //     if (packageId) {
    //         const meta = {
    //             source_name: getSourceName(location.pathname),
    //             package_id: packageId,
    //             rail_name: railTitle,
    //             rail_id: railId,
    //         };
    //         //contentDetailsEvent(meta, EventType.RAIL_VISIBLE); //RailEventHOC fires railVisible event
    //     }
    // }, []);
    const pywScrollHandler = () => {
        clearTimeout(scrollingTimerRef.current);
        scrollingTimerRef.current = setTimeout(() => {
            const topVisibleTileIndex = getTopVisibleTileIndex();
            // tileScrollAnalytics({ endTileIndex: topVisibleTileIndex, scrollType: 'vertical' });
            setRailIndex(topVisibleTileIndex);
        }, ACTIVE_TILE_AFTER_TIMEOUT);
    };

    useEffect(() => {
        browserStore.remove(LOCAL_STORE_KEYS?.PYW_TRAILER_AUTOPLAY_MUTE);
    }, []);

    useEffect(() => {
        const carouselEle = carouselElementRef?.current;
        const refEle = ref?.current;
        if (isPYWRail) {
            if (isMobileViewPort() && ref && ref.current) {
                ref.current.addEventListener('scroll', pywScrollHandler);
            }
            // else if (!isMobile() && carouselElementRef && carouselElementRef.current) {
            //     carouselElementRef.current.addEventListener('wheel', handleWheelScroll);
            // }
        }
        return () => {
            // clearTimeout(wheelingTimerRef.current);
            clearTimeout(scrollingTimerRef.current);
            // carouselEle && carouselEle.removeEventListener('wheel', handleWheelScroll);
            refEle && refEle.removeEventListener('scroll', pywScrollHandler);
        };
    }, [
        tileElements,
    ]);

    useEffect(() => {
        if (carouselElementRef && carouselElementRef.current && !isPYWRail) {
            const images = carouselElementRef.current.children;
            carouselElementRef.current.addEventListener('scroll', (event) => {
                const railIndex = Math.ceil(carouselElementRef.current.scrollLeft / (images[0].offsetWidth + 4));
                setTimeout(() => {
                    setRailIndex(railIndex);
                }, ACTIVE_TILE_AFTER_TIMEOUT);
            });
        }
    }, [
        tileElements,
    ]);

    const showScrollButtons = () => {
        if (!checkWindowExist()) {
            return;
        }
        if (isPYWRail) {
            return indexOfRail < tileElements.length - 1;
        }
        const { availWidth } = window && window.screen;
        const totalTileWidth = tileElements.length * 157;

        return (availWidth - 160) / totalTileWidth < 1;
    };

    const previous = () => {
        if (carouselElementRef.current) {
            if (isPYWRail) {
                pywPrevious();
                return;
            }
            const scrollLeft = carouselElementRef.current.clientWidth - 170;
            carouselElementRef.current.scrollLeft -= scrollLeft;
            setRailIndex(indexOfRail - 1);
        }
    };

    const next = () => {
        // if (indexOfRail >= (tilesLength - 7)) {
        //     return;
        // }

        if (carouselElementRef.current) {
            const images = carouselElementRef.current.children;
            if (images && images.length > 0) {
                if (isPYWRail) {
                    pywNext();
                    return;
                }
                const scrollLeft = carouselElementRef.current.clientWidth - 170;
                carouselElementRef.current.scrollLeft += scrollLeft;
                setTimeout(() => {
                    setRailIndex(indexOfRail + 1);
                }, ACTIVE_TILE_AFTER_TIMEOUT);
            }
        }
    };


    if (updatedTiles.length === 0) {
        return null;
    }
    const { scrollLeft = 0, clientWidth = 0, scrollWidth = 0 } = carouselElementRef && carouselElementRef.current ? carouselElementRef.current : {};
    const showNext = showRailScrollButtons && (scrollLeft < (scrollWidth - clientWidth) || (scrollLeft === 0 && tileElements.length > 7) || (isPYWRail && indexOfRail < tileElements?.length - 1));
    const showLeftScroll = showRailScrollButtons && !isMobileViewPort() && (isPYWRail ? indexOfRail > 0 : scrollLeft > 0);
    const showSeeAll = () => {
        if (!showMore || !actionTitle) {
            return false;
        }
        if ((pageId && webPageId) && pageId === MORE_LIKE_THIS && !isMobileViewPort()) {
            return false;
        }
        if (railCtaActionUrl !== null && packageId && packageId !== '') {
            return true;
        }
        if (!!railCtaActionUrl && showMore) {
            return true;
        }
        return false;
    };
    let style = {};
    const imgWidth = isBot() || isMobileViewPort() ? 500 : windowWidth;
    const imageFormat = deviceUtil.getImageFormat(bgImg);
    const imageUrl = ThumborUtil.getThumborUrl(imgWidth, 0, bgImg, imageFormat);
    if (bgImg) {
        style = {
            background: `url('${imageUrl}') center top no-repeat`,
            backgroundSize: 'cover',
        };
    }

    const navigateBack = () => {
        if (checkWindowExist() && hasPrevPath) {
            history?.back();
        }
        else {
            history.push({ pathname: ROUTE_PATHS.ROOT });
        }
    };

    let hideHeaderClass = '';
    let pywBgImageUrl = backgroundImageUrl;
    if (isPYWRail && shouldShowHeaderView) {
        pywBgImageUrl = isMobileViewPort() ? backgroundImageUrlMWeb : backgroundImageUrl;
        hideHeaderClass = pywBgImageUrl ? '' : 'no-header';
    }

    return (
        <section
            className={`more-like-container scrollLableRail margin-padding-0-mweb m-30-bottom margin-rail-bottom pyw-m-0-b ${bgImg ? 'highlight-rail' : ''} ${containerClass} ${railCollapsible ? (isRailCollapsed ? 'collapsed' : 'open') : ''}`}
            id="atm_potrait-container"
            data-id={`${ELEMENT_ID.RAIL_ID}-${railIndex}`}
            name={railTitle}
            style={style}
            ref={ref}

        >
            {isPYWRail && (
                <>
                    {isMobileViewPort() ? (
                        <AnalyticsButtonComponent
                            className="m-web-back-pyw"
                            onClick={navigateBack}
                        >
                            <PYWBackButton />
                        </AnalyticsButtonComponent>
                    ) : null}
                    {!hideHeaderClass ? (
                        <div className="pyw-bg-holder">
                            <ThumborImage
                                className="pym-background-img"
                                imageSettings={{ 500: 4000 }}
                                src={pywBgImageUrl}
                                defaultImageWidth={800}
                            />
                        </div>
                    ) : (
                        null
                    )}
                </>
            )}
            <section
                className={`cdp-rail-title ${railCollapsible ? 'collapse' : ''}`}
                onClick={() => {
                    if (railCollapsible) {
                        setIsRailCollapsed(!isRailCollapsed);
                    }
                }}
            >
                <AnalyticsLinkComponent
                    meta={moreAnalyticsMeta}
                    className={`home-page-font text-bold-20 mobile-text-bold-16 ${railSubtitle ? 'increase-logo-height' : ''}`}
                    to={showAll && showMore ? railCtaActionUrl : '#'}
                    onClick={(e) => {
                        if (!(showAll && showMore)) {
                            e.preventDefault();
                        }
                    }}
                    id={`${ELEMENT_ID.RAIL_TITLE}-${railIndex}`}
                >
                    {
                        animationData && (
                            <Lottie
                                className="inline-block cdp-rail-title-logo"
                                loop
                                animationData={animationData}
                                play
                            />
                        )
                    }
                    {!animationData && titleLogo ? (
                        <ThumborImage
                            className="cdp-rail-title-logo"
                            src={titleLogo}
                            alt=""
                            imageSettings={titleLogoImageSettings}
                        />
                    ) : ''}
                    <div>
                        <span
                            className="line-ellipsis"
                            dangerouslySetInnerHTML={{ __html: formatedRailTitle || railTitle }}
                            style={{ color: titleColor }}
                        />
                        {railSubtitle && <div className="rail-subtitle">{railSubtitle}</div>}
                    </div>
                </AnalyticsLinkComponent>

                {/* {showScrollButtons() } */}
                { showSeeAll() && (
                    <AnalyticsLinkComponent
                        className="cdp-see-all-button"
                        to={`${railCtaActionUrl}${seeAllArtWork ? `&artwork=${seeAllArtWork}` : ''} `}
                        meta={moreAnalyticsMeta}
                    >
                        <AnalyticsButtonComponent className="text-normal-12">{actionTitle}</AnalyticsButtonComponent>
                        {pageId !== MORE_LIKE_THIS && (
                            <ArrowIcon />
                        ) }
                    </AnalyticsLinkComponent>
                ) }
                {railCollapsible ? (
                    <div style={{ display: isRailCollapsed ? 'block' : 'none' }}>
                        { collapsedComponent }
                    </div>
                ) : null}
            </section>
            {subTitle && <h3 className="sub-text  heading-side-padding landscape-side-space">{subTitle}</h3>}
            {!isRailCollapsed ? (
                <section
                    className={`end-to-end-rail-wrapper card-row-wrapper ${isFreeChannelsRail ? 'free-container' : ''} ${hoveredTile ? 'shrink-space' : ''} ${hideHeaderClass}`}
                    id={`${ELEMENT_ID.POTRAIT_RAIL}-${railId}-${railIndex}`}
                    ref={railRef}
                >
                    { showLeftScroll && (
                        <AnalyticsButtonComponent
                            className={`slide-btn-cdp left ${slideBtnClass || 'portrait'} ${customRailSlideBtnClass}`}
                            onClick={previous}
                            aria-label="Scroll Rail Left"
                            disabled={disableTileNavigation}
                        >
                            <ThumborImage
                                src="/static/cdp-assets/chev-sside.svg"
                            />
                        </AnalyticsButtonComponent>
                    ) }
                    <ul
                        className={`wrapper-inner ${hoveredTile ? 'padding-booster' : ''}`}
                        ref={carouselElementRef}
                        id={railId}
                        onScroll={onRailScroll}
                    >
                        { lazyTiles }
                    </ul>
                    { !isMobileViewPort() && showScrollButtons() && showNext && (
                        <AnalyticsButtonComponent
                            className={`slide-btn-cdp right ${slideBtnClass || 'portrait'} ${customRailSlideBtnClass}`}
                            onClick={() => next(tileElements.length)}
                            aria-label="Scroll Rail Right"
                            disabled={disableTileNavigation}
                        >
                            <ThumborImage
                                src="/static/cdp-assets/chev-sside.svg"
                            />
                        </AnalyticsButtonComponent>
                    ) }

                </section>
            ) : null}
        </section>
    );
});

function mapStateToProps(state, props) {
    const {
        appConfig,
        contentDetails,
        distroDetails: {
            distroData = [],
        } = {},
        location: { navigationObj: { prevPath = '', prevSource = '', currentSource = '' } = {} } = {},
    } = state;

    const { match: { params } } = props;
    return {
        appConfig,
        contentDetails,
        params,
        distroData,
        cpDetailsById: appConfig?.cpDetailsById,
        hasPrevPath: prevPath,
        currentSource,
        prevSource,
    };
}

XstreamLayoutComponent.defaultProps = {
    tiles: [],
    railCtaActionUrl: '',
    actionTitle: '',
    sosAnalyticsMeta: {},
    packageId: '',
    titleType: '',
    maxTileTitleLines: '',
    style: {},
};

XstreamLayoutComponent.propTypes = {
    tiles: PropTypes.array, // eslint-disable-line react/forbid-prop-types,
    railTitle: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    railCtaActionUrl: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired,
    onRailScroll: PropTypes.func.isRequired,
    actionTitle: PropTypes.string,
    sosAnalyticsMeta: PropTypes.object,
    packageId: PropTypes.string,
    titleType: PropTypes.string,
    maxTileTitleLines: PropTypes.number,
    style: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, {
})(withDeviceUtil(withWatchlistDataHOC(withStyles(styles)(CrashCaptureHOC(RailEventHOC(XstreamLayoutComponent)))))));

import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { LOGIN_SOURCE, SUBSCRIPTION_SOURCE } from '@airtel-tv/constants/EventsConst';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { filterSubscribedPlans, getPlaceholderFromLocal, getXStreamDetail } from '@airtel-tv/utils/GlobalUtil';
import { getCurrentWindowSourceName } from '@airtel-tv/utils/WindowUtil';
import { subscriptionClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { triggerLogin } from '../../../../../web/src/modules/auth/AuthAction';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';

import styles from './TrailerSubscribeCTA.scss';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';

const TrailerSubscribeCTA = (props) => {
    const dispatch = useDispatch(triggerLogin);
    const  { isIOS } = props;
    const navigate = useNavigate();
    const [
        hidePopup,
        setHidePopup,
    ] = useState(isIOS() ? true : false);

    const isAuthenticated = useSelector(state => state.authConfig.isAuthenticated);
    const contentDetails = useSelector(state => state.contentDetails);
    const allPlans = useSelector(state => state.planConfig);
    const LANGUAGE = useMemo(() => LanguageProviderUtil.getLanguage(), []);

    const triggerLoginDispatch = useCallback(params => dispatch(triggerLogin(params)));
    const location = useLocation();
    let isXppSubscribed = false;
    let isRenewable = true;
    let browsePlans = [];
    if (isAuthenticated) {
        const subscribedPlans = filterSubscribedPlans(allPlans && allPlans.subscribedPlans);
        const xppDetails = getXStreamDetail(subscribedPlans);
        // eslint-disable-next-line prefer-destructuring
        browsePlans = allPlans.browsePlans;
        isXppSubscribed = !!xppDetails;
        isRenewable = xppDetails && xppDetails.preReminder && xppDetails.renewPlanId && xppDetails.renewPlanId !== 0;
    }
    const handlePremiumClick = useCallback(() => {
        subscriptionClickEvent({
            asset_name: SUBSCRIPTION_SOURCE.TRAILER_PLAY,
            source_name: getCurrentWindowSourceName(),
            channel_name: contentDetails.cpId,
        });
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.TRAILER_XSTREAM_SUBSCRIPTION,
                redirectUrl: `${ROUTE_PATHS.SUBSCRIPTION_PAGE}?from=trailerCta`,
            });
            return;
        }
        navigate(`${ROUTE_PATHS.SUBSCRIPTION_PAGE}?from=trailerCta`);
    }, [
        props.cpId,
        isAuthenticated,
        triggerLoginDispatch,
        location,
    ]);

    const cta = useMemo(() => {
        const {
            TRAILER_PREMIUM_CTA_TEXT, SUBSCRIBE, TRAILER_PREMIUM_EXTEND_CTA_TEXT, TRAILER_PREMIUM_EXTEND_CTA_BTN_TEXT, TRAILER_SUB_CTA,
        } = LANGUAGE;


        return isXppSubscribed ? {
            ctaText: TRAILER_PREMIUM_EXTEND_CTA_TEXT || getPlaceholderFromLocal('TRAILER_PREMIUM_EXTEND_CTA_TEXT'),
            btnText: TRAILER_PREMIUM_EXTEND_CTA_BTN_TEXT || getPlaceholderFromLocal('TRAILER_PREMIUM_EXTEND_CTA_BTN_TEXT'),
        } : {
            ctaText: TRAILER_PREMIUM_CTA_TEXT || getPlaceholderFromLocal('TRAILER_PREMIUM_CTA_TEXT'),
            btnText: SUBSCRIBE || getPlaceholderFromLocal(SUBSCRIBE),
        };
    }, [
        isAuthenticated,
        allPlans,
        LANGUAGE,
    ]);


    const logoUrl = useMemo(() => {
        const xtreamPlan = getXStreamDetail(allPlans && allPlans.browsePlans);
        return (xtreamPlan || { partnerLogo: IMAGE_PATHS.XSTREAM_PREMIUM_CIRCULAR }).partnerLogo;
    }, [
        allPlans,
    ]);

    if (isXppSubscribed) {
        return '';
    }

    const {
        SUBSCRIBE_TEXT = 'Subscribe at just ₹149/month',
        WATCH_MOVIES_TEXT = 'Watch over 10K movies and series on 15+ OTTs',
    } = LANGUAGE?.TRAILER_SUB_CTA || {};

    return (
        <div
            className={`subscribe-parent ${hidePopup ? 'd-none' : ''}`}
        >
            <div
                className="cover-img"
            >
                <ThumborImage
                    src={IMAGE_PATHS.SUB_AIRTEL_LOGO}
                />
            </div>
            <div className="cta-text">
                {SUBSCRIBE_TEXT}
                <span className="sub-text">{WATCH_MOVIES_TEXT}</span>
            </div>
            <AnalyticsButtonComponent
                className="cta-btn common-white-btn"
                onClick={() => handlePremiumClick()}
            >
                {cta.btnText}
            </AnalyticsButtonComponent>
            <AnalyticsButtonComponent
                className="external-close"
                onClick={() => setHidePopup(true)}
                aria-label="Close Pop up"
            />
        </div>
    );
};

TrailerSubscribeCTA.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    cpId: PropTypes.string.isRequired,
};

TrailerSubscribeCTA.defaultProps = {};


export default withStyles(styles)(TrailerSubscribeCTA);

/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
// import { changeMetaData, showPlayback } from '../../notify/NotifyActions';
import { packageReset } from '@airtel-feature/layout/actions/LayoutActions';
import {
    DIMENSION_PAGE_MAP, ONEHUB_INGRESS_INTENT, PROGRAM_TYPES, ROLE_TYPES,
} from '@airtel-tv/constants/GlobalConst';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
// import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { updatePageBreadCrumbs } from '@airtel-tv/ui-lib/molecules/breadcrumbs/actions/BreadCrumbsActions';
import { getPosterUrlFromContentDetails } from '@airtel-tv/utils/ContentImageProvider';
import { secondsTohhhmmm, getAmazonAppInAppMeta } from '@airtel-tv/utils/GlobalUtil';
import { getCDPFooterContent, getLangfromCode, getPageSeoMeta } from '@airtel-tv/utils/ContentMetaUtil';
import { BreadCrumbsUtil } from '@airtel-tv/utils/BreadCrumbsUtil';
// import MarkupFactory from '../../watch-actions/factories/MarkupFactory';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
// import withDeviceUtil from '../../shared/hoc/WithDeviceUtil';
import { withWatchlistDataHOC } from '@airtel-tv/lib';
// import withWatchlistDataHOC from '../../shared/hoc/withWatchlistDataHOC';
import { getWindowLocation, checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import { RAIL_TYPES } from '@airtel-tv/constants';
import { updateOnPageDescriptionAction } from '../../../../web/src/modules/footer/actions/FooterAction';
// import { updateOnPageDescriptionAction } from '../../footer/actions/FooterAction';
import { purchaseSubscriptionInit } from '../../../../web/src/modules/subscription-details/action/SubscriptionAction';
// import { purchaseSubscriptionInit } from '../../subscription-details/action/SubscriptionAction';
import MarkupFactory from '../../../../web/src/modules/watch-actions/factories/MarkupFactory';
import {
    contentDetailsFetchAction, contentDetailsFetchCompletedAction, emptyDetailAction, fetchParticularEpisodeFetchAction, seriesDetailsAndTabFetchAction, seriesDetailsFetchAction,
} from '../../content-details/actions/ContentDetailsAction';
import { changeMetaData, hidePlayback, showPlayback } from '../../../../web/src/modules/notify/NotifyActions';
import PlayerContainer from './PlayerContainer';
import BannerContentContainer from '../component/BannerWrapperComponent/BannerContentContainer';
// import env from '../../../config';
import { showNonAirtelUserAction } from '../../../../web/src/modules/user/UserAction';
// import { showNonAirtelUserAction } from '../../user/UserAction';
// import LoaderComponent from '../../../../web/src/modules/shared/components/loader/LoaderComponent';

// const env = AppEnv.getEnv();

class BannerContentRailContainer extends Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            contentId: '',
            playableId: '',
            actions: null,
            seoMetaUpdated: false,
        };
        if (!checkWindowExist()) {
            this.updateSeoMeta();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            contentId,
            continueWatchingData,
            tiles,
            location,
            contentCollection,
            match: { path, params },
            layoutPackages,
            deviceUtil,
            amazonRedirectionConfig,
        } = nextProps;
        const { contentDetails } = tiles && tiles[0];
        let { playableId } = contentDetails;
        const {
            gtiId = '', programType = '', id = '', seriesId = '', trailers = [],
            cpId = '',
        } = contentDetails;
        let trailerRefId = '';
        const tvShowId = (
            programType === PROGRAM_TYPES.TVSHOW
            || programType === PROGRAM_TYPES.MOVIE
            || programType === PROGRAM_TYPES.VIDEO
                ? id
                : seriesId
        );
        if (location?.search?.includes('showPlaybackTrailer')) {
            const { trailerId } = LocationUtil.getQueryParams(nextProps);
            playableId = trailerId || (trailers && trailers[0].refId);
            trailerRefId = trailerId || (trailers && trailers[0].refId);
        }
        else if (contentCollection.playableId && !contentCollection.playableId.isFetching) {
            playableId = contentCollection?.playableId;
        }
        else if (continueWatchingData && continueWatchingData[tvShowId] && Object.keys(continueWatchingData[tvShowId]).length > 0) {
            playableId = continueWatchingData?.[tvShowId]?.contentResponse?.playableId;
        }
        else if (contentDetails.programType === PROGRAM_TYPES.TVSHOW) {
            const { seasonId, episodeId } = params;
            if (seasonId && episodeId) {
                playableId = episodeId;
            }
        }
        else {
            const { currentTab } = contentCollection;
            const episodes = lodashGet(contentCollection, currentTab, []);
            if (episodes.length > 0) {
                playableId = episodes[0].refId;
            }
        }
        // else if ((path === ROUTE_PATHS.TV_EPISODE_PLAYBACK && contentDetails.programType === PROGRAM_TYPES.EPISODE) || (path === ROUTE_PATHS.PLAYBACK)) {
        //     playableId = contentDetails.playableId;
        // }
        let bannerActions = null;
        if (layoutPackages && layoutPackages.length > 0) {
            layoutPackages.forEach((rail) => {
                const {
                    format: {
                        ty = '', action = {}, ds = '', ts = '',
                    } = {},
                    zionDisplay = {},
                } = rail;
                if (ty === 'CONTENT_PLAY_RAIL') {
                    bannerActions = {
                        railCtaType: action?.st || '',
                        railCtaText: deviceUtil.isScopedWebview() ? 'Watch Now' : action?.t || '',
                        railCtaMeta: action?.meta || '',
                        actionTitle: action?.t || '',
                        railSecondaryText: ds,
                        action,
                        railPrimaryText: ts,
                    };
                }
                else if (ty === RAIL_TYPES.X_CONTENT_REDIRECTION_RAIL) {
                    const appInAppMeta = getAmazonAppInAppMeta({
                        gtiId,
                        ...amazonRedirectionConfig,
                    });
                    bannerActions = {
                        ...bannerActions,
                        partnerActivationRail: {
                            title: zionDisplay?.title || '',
                            appInAppMeta,
                            cpId,
                            contentId: id,
                            gtiId,
                        },
                    };
                }
            });
        }
        if (
            !prevState
            || prevState.contentId !== contentId
            || prevState.playableId !== playableId
            || prevState.bannerActions !== bannerActions

        ) {
            return {
                bannerActions,
                contentId,
                playableId,
                trailerRefId,
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    componentDidMount() {
        this.init();
        if (!this.state.seoMetaUpdated) {
            this.updateSeoMeta();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            showPlaybackDispatch, location, playableId, showPlayback, layoutPackages, hidePlaybackDispatch, blogConfig,
        } = this.props;
        const { contentId } = this.state;
        if (prevState.contentId !== contentId || prevState.playableId !== playableId) {
            if (location.search.includes('showPlayback')) {
                showPlaybackDispatch();
            }
            else {
                hidePlaybackDispatch();
            }
        }
        if (prevState.contentId !== contentId || prevProps.blogConfig !== blogConfig) {
            this.setState({ seoMetaUpdated: true }, () => {
                this.updateSeoMeta();
            });
        }

        if (prevProps.showPlayback && prevProps.showPlayback !== showPlayback) {
            this.init(true);
        }
    }

    componentWillUnmount() {
        const { emptyDetailDispatch } = this.props;
        emptyDetailDispatch();
    }

    init = (isPlaybackClosed) => {
        const {
            showPlaybackDispatch, location, continueWatchingData, tiles, contentCollection, seriesDetailsFetchActionDispatch, contentDetailsFetchCompletedActionDispatch, contentDetailsFetchActionDispatch,
            fetchParticularEpisodeFetchActionDispatch, history, match: { path, params }, seriesDetailsAndTabFetchActionDispatch,
        } = this.props;
        const { currentTab, showPlayback } = LocationUtil.getQueryParams(this.props);
        const { contentDetails: { programType, id }, contentDetails } = tiles && tiles[0];
        if (showPlayback) {
            showPlaybackDispatch();
        }
        if ([
            PROGRAM_TYPES.VIDEO,
            PROGRAM_TYPES.MOVIE,
        ].includes(programType)) {
            const payload = {
                [id]: {
                    ...contentDetails,
                    error: null,
                    fetching: false,
                },
                playableId: contentDetails.playableId,
            };
            contentDetailsFetchCompletedActionDispatch({ payload });
        }
        else if (programType === PROGRAM_TYPES.TVSHOW) {
            const { seasonId, episodeId } = params;
            if (seasonId && episodeId) {
                const { id } = contentDetails;
                let playId;
                // if(continueWatchingData[id]) {
                //     const { contentResponse: {playableId } } = continueWatchingData[id];
                //     playId = playableId;
                // }
                if (!isPlaybackClosed) {
                    seriesDetailsFetchActionDispatch({
                        contentId: id,
                        seasonId,
                    });
                    if (seasonId) {
                        fetchParticularEpisodeFetchActionDispatch({
                            seasonId,
                            playId,
                            episodeId,
                        });
                    }
                }
                else {
                    seriesDetailsFetchActionDispatch({
                        contentId: id,
                        seasonId: contentCollection.currentSeasonId,
                    });
                }
            }
            else if (seasonId) {
                const { id } = contentDetails;
                let playId;
                if (continueWatchingData[id]) {
                    const { contentResponse: { playableId } } = continueWatchingData[id];
                    playId = playableId;
                }
                if (!isPlaybackClosed) {
                    seriesDetailsAndTabFetchActionDispatch({
                        contentId: id,
                        seasonId,
                    });
                }
                else {
                    seriesDetailsFetchActionDispatch({
                        contentId: id,
                        seasonId: contentCollection.currentSeasonId,
                    });
                }
                if (playId) {
                    contentDetailsFetchActionDispatch(playId);
                }
            }
            else {
                const { seriesTvSeasons, id } = contentDetails;
                const { contentResponse } = lodashGet(continueWatchingData, id, {});
                const { playableId, seasonId: seasonIdFromContWatching } = (contentResponse && contentResponse) || contentDetails;
                const seasonId = seasonIdFromContWatching || null;
                !isPlaybackClosed ? seriesDetailsAndTabFetchActionDispatch({
                    contentId: id,
                    seasonId,
                }) : seriesDetailsFetchActionDispatch({
                    contentId: id,
                    seasonId: contentCollection.currentSeasonId,
                });
                if (playableId) {
                    contentDetailsFetchActionDispatch(playableId);
                }
                // if (seasonId) {
                //     fetchTabsActionDispatch({ seasonId, tab: currentTab });
                // }
            }
        }
    };

    updateSeoMeta = () => {
        const {
            changeMetaDataDispatch, tiles, updatePageBreadCrumbsDispatch, contentCollection, customCdpContent = {}, updateOnPageDescriptionActionDispatch, cpDetailsById, cdpPostFooterMapping, blogConfig,
        } = this.props;
        const { contentDetails } = tiles && tiles[0];
        const {
            programType, title, id, tvShowName, episodeNum, seriesId, description, genres, languages, cpId, releaseYear,
        } = contentDetails;
        const {
            currentTab, currentSeasonId, seasons, playableId,
        } = contentCollection;

        const episodesDetails = contentCollection && contentCollection[currentTab];
        const seasonDetails = seasons && currentSeasonId && seasons.find(season => season.seasonId === currentSeasonId);
        let metaObject = {};
        const channelPartnerName = lodashGet(cpDetailsById[cpId], 'title', '');
        const genreForMeta = genres && genres.join(' ') || '';
        const metaLanguage = languages && languages[0] ? languages[0] : '';
        metaObject = {
            programType,
            contentTitle: title,
            contentDescription: description,
            contentLanguage: metaLanguage,
            genreForMeta,
            releaseYear,
            // actorForMeta,
        };
        if (programType === PROGRAM_TYPES.EPISODE || programType === PROGRAM_TYPES.TVSHOW) {
            metaObject.tvShowDetails = {
                showName: programType === PROGRAM_TYPES.TVSHOW ? title : tvShowName,
                seasonNumber: seasonDetails && seasonDetails.seasonNumber,
                episodeNumber: episodeNum,
                episodeName: title,
                channelName: channelPartnerName,
            };
        }

        let headMetaData = getPageSeoMeta(metaObject);

        const seoMarkups = [];
        let curPageLink = '/';
        let showPageLink = '/';
        const breadCrumbsList = [];
        let episodeDetails;
        let footerContent;
        const windowLocation = getWindowLocation();
        switch (contentDetails.programType) {
            case PROGRAM_TYPES.EPISODE:
                curPageLink = windowLocation ? windowLocation.pathname : RoutingUtil.getTvEpisodeUrlWeb({
                    programType,
                    title: tvShowName,
                    episodeNumber: episodeNum,
                    episodeName: title,
                    seasonName: seasonDetails && seasonDetails.title && seasonDetails.name,
                    contentId: id,
                });
                showPageLink = RoutingUtil.getContentPlaybackUrlWeb({
                    category: programType,
                    contentTitle: tvShowName,
                    contentId: seriesId,
                });
                breadCrumbsList = BreadCrumbsUtil.tvEpisodePageBreadCrumb({
                    contentDetails,
                    curPageLink,
                    showPageLink,
                });
                seoMarkups = MarkupFactory({
                    contentDetails,
                    tvShowDetails: contentCollection && contentCollection[seriesId],
                    seasonDetails,
                    baseUrl: this.deviceUtil.getBaseUrl(),
                    breadCrumbsList,
                });
                updatePageBreadCrumbsDispatch({ breadCrumbsList });
                if (customCdpContent[id]) {
                    const { seoFooterContent = '', seoTitle = '', seoDescription = '' } = customCdpContent[id];
                    footerContent = seoFooterContent;
                    headMetaData = {
                        ...headMetaData,
                        title: seoTitle || headMetaData.title,
                        description: seoDescription || headMetaData.description,
                    };
                }
                else if (customCdpContent[seriesId]) {
                    const { seoFooterContent = '', seoTitle = '', seoDescription = '' } = customCdpContent[seriesId];
                    footerContent = seoFooterContent;
                    headMetaData = {
                        ...headMetaData,
                        title: seoTitle || headMetaData.title,
                        description: seoDescription || headMetaData.description,
                    };
                }
                changeMetaDataDispatch({
                    headMetaData: {
                        ...headMetaData,
                        image: getPosterUrlFromContentDetails(contentDetails) || getPosterUrlFromContentDetails(episodesDetails),
                        link: curPageLink,
                        watchActionCatalogues: seoMarkups,
                    },
                });
                break;

            case PROGRAM_TYPES.TVSHOW:
                curPageLink = windowLocation ? windowLocation.pathname : RoutingUtil.getContentPlaybackUrlWeb({
                    category: programType,
                    contentTitle: title,
                    contentId: id,
                });
                breadCrumbsList = BreadCrumbsUtil.tvShowPageBreadCrumb({
                    contentDetails,
                    curPageLink,
                });
                seoMarkups = MarkupFactory({
                    contentDetails,
                    baseUrl: this.deviceUtil.getBaseUrl(),
                    breadCrumbsList,
                });
                if (customCdpContent[id]) {
                    const { seoFooterContent = '', seoTitle = '', seoDescription = '' } = customCdpContent[id];
                    footerContent = seoFooterContent;
                    headMetaData = {
                        ...headMetaData,
                        title: seoTitle || headMetaData.title,
                        description: seoDescription || headMetaData.description,
                    };
                }
                changeMetaDataDispatch({
                    headMetaData: {
                        ...headMetaData,
                        image: getPosterUrlFromContentDetails(episodesDetails),
                        link: curPageLink,
                        watchActionCatalogues: seoMarkups,
                    },
                });
                updatePageBreadCrumbsDispatch({ breadCrumbsList });
                episodeDetails = contentCollection[playableId];
                break;

            case PROGRAM_TYPES.MOVIE:
                curPageLink = windowLocation ? windowLocation.pathname : RoutingUtil.getContentPlaybackUrlWeb({
                    category: programType,
                    contentTitle: title,
                    contentId: id,
                });
                let breadCrumbsList = BreadCrumbsUtil.moviePageBreadCrumbs({
                    contentDetails,
                    curPageLink,
                });
                let seoMarkups = MarkupFactory({
                    contentDetails,
                    baseUrl: this.deviceUtil.getBaseUrl(),
                    breadCrumbsList,
                });
                if (customCdpContent[id]) {
                    const { seoFooterContent = '', seoTitle = '', seoDescription = '' } = customCdpContent[id];
                    footerContent = seoFooterContent;
                    headMetaData = {
                        ...headMetaData,
                        title: seoTitle || headMetaData.title,
                        description: seoDescription || headMetaData.description,
                    };
                }
                changeMetaDataDispatch({
                    headMetaData: {
                        ...headMetaData,
                        image: getPosterUrlFromContentDetails(contentDetails),
                        link: curPageLink,
                        watchActionCatalogues: seoMarkups,
                    },
                });
                updatePageBreadCrumbsDispatch({ breadCrumbsList });
            default:
                break;
        }
        if (!footerContent) {
            const footerMeta = this.getFooterMeta({
                episodeDetails,
                contentDetails,
            });
            footerContent = getCDPFooterContent(footerMeta);
        }
        const { cdpOnPageData = {} } = blogConfig;
        const postId = cdpPostFooterMapping?.[id];
        const { content = {}, yoast_head_json: headJson = {} } = (postId && cdpOnPageData?.[postId]) || {};
        if (content && Object.keys(content).length > 0) {
            const { rendered } = content;
            updateOnPageDescriptionActionDispatch(
                { onPageDescription: rendered },
            );
            if (headJson) {
                const { description = '', title = '' } = headJson;
                headMetaData = {
                    ...headMetaData,
                    title,
                    description,
                };
                changeMetaDataDispatch({
                    headMetaData: {
                        ...headMetaData,
                        image: getPosterUrlFromContentDetails(contentDetails),
                        link: curPageLink,
                        watchActionCatalogues: seoMarkups,
                    },
                });
            }
        }
        else if (footerContent) {
            updateOnPageDescriptionActionDispatch(
                { onPageDescription: footerContent },
            );
        }
        this.setState({ seoMetaUpdated: true });
    };


    getFooterMeta = ({ episodeDetails = {}, contentDetails }) => {
        let {
            programType, title, credits, genres, languages, durSec, downloadable, cpId,
        } = contentDetails;
        const { cpDetailsById } = this.props;
        if (programType == PROGRAM_TYPES.TVSHOW) {
            durSec = episodeDetails.durSec;
            downloadable = episodeDetails.downloadable;
        }

        const actorsForFooter = (credits || []).map((actor) => {
            const actorUrl = RoutingUtil.getArtistUrlWeb({
                name: actor.displayTitle,
                id: actor.id,
            });
            return {
                ...actor,
                actorUrl,
            };
        });

        const languageMap = (languages || []).map(item => ({
            name: getLangfromCode({ lang: item }),
            url: RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP[programType],
                lang: getLangfromCode({ lang: item }),
            }),
        }));

        const genreMap = genres && genres.map(item => ({
            name: item,
            url: RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP[programType],
                genres: item,
            }),
        })) || [];

        const duration = durSec ? secondsTohhhmmm(durSec) : '';

        const channelPartnerName = lodashGet(cpDetailsById[cpId], 'title', '');
        const pageLink = lodashGet(cpDetailsById[cpId], 'pageLink', '');

        const channelUrl = RoutingUtil.getChannelPartnerPageUrl({
            channelPartnerName,
            pageLink,
        });

        return {
            programType: programType === PROGRAM_TYPES.MOVIE ? PROGRAM_TYPES.MOVIE : PROGRAM_TYPES.TVSHOW,
            actorsForFooter,
            channelPartnerName,
            contentTitle: title,
            languageMap,
            genreMap,
            duration,
            channelUrl,
            baseUrl: this.deviceUtil.getBaseUrl(),
            downloadable,
        };
    };

    NavigateToPaymentPage = ({ cpId }) => {
        const {
            userConfig, showNonAirtelUserAction,
            history,
        } = this.props;
        if (userConfig.customerType === 'NON_AIRTEL') {
            showNonAirtelUserAction({ cpId });
            return;
        }
        const url = RoutingUtil.getOneHubPageCDP({
            ingressIntent: ONEHUB_INGRESS_INTENT.CONTENT,
            cpId,
        });
        history.push({
            pathname: url,
        });
    };

    render() {
        const { tiles, showPlayback, pageId } = this.props;
        const {
            contentId, playableId, bannerActions, trailerRefId,
        } = this.state;
        const tile = tiles && tiles.length > 0 ? tiles[0] : {};
        return (
            <>
                { playableId && showPlayback && checkWindowExist() ? (
                    <PlayerContainer
                        contentId={contentId}
                        contentDetails={tile.contentDetails}
                        playableId={playableId}
                        trailerRefId={trailerRefId}
                        pageId={pageId}
                    />
                ) : (
                    <BannerContentContainer
                        bannerActions={bannerActions}
                        NavigateToPaymentPage={this.NavigateToPaymentPage}
                        trailerRefId={trailerRefId}
                        {...this.props}
                        {...tile}
                    />
                ) }
            </>
        );
    }
}

BannerContentRailContainer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tiles: PropTypes.array.isRequired,
    showPlayback: PropTypes.bool.isRequired,
    contentId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    showPlaybackDispatch: PropTypes.func.isRequired,
    packageResetDispatch: PropTypes.func.isRequired,
    seriesDetailsFetchActionDispatch: PropTypes.func.isRequired,
    blogConfig: PropTypes.object,
    cdpPostFooterMapping: PropTypes.object,
};

BannerContentRailContainer.defaultProps = {
    blogConfig: {},
    cdpPostFooterMapping: {},
};

function mapStateToProps(state, props) {
    const {
        notify: {
            showPlayback,
        },
        syncContent: {
            continueWatchingData,
        },
        layoutDetails,
        appConfig: { web_pageId: webPageId, DEEPLINK_SUBSCRIPTION_PLAN: planDetails = {}, cdpPostFooterMapping },
        contentDetails: contentCollection,
        appConfig: {
            cpDetailsById,
            customCdpContent,
            amazonRedirectionConfig = {},

        },
        userConfig,
        blogConfig,
    } = state;
    const layoutPackages = layoutDetails?.contentDetail?.layoutPackages || [];
    const { match } = props;
    return {
        showPlayback,
        continueWatchingData,
        contentCollection,
        match,
        webPageId,
        cpDetailsById,
        customCdpContent,
        planDetails,
        userConfig,
        // cdpPostFooterMapping,
        blogConfig,
        layoutPackages,
        cdpPostFooterMapping,
        amazonRedirectionConfig,
    };
}

export default withRouter(connect(
    mapStateToProps, {
        showPlaybackDispatch: showPlayback,
        hidePlaybackDispatch: hidePlayback,
        packageResetDispatch: packageReset,
        seriesDetailsFetchActionDispatch: seriesDetailsFetchAction,
        contentDetailsFetchActionDispatch: contentDetailsFetchAction,
        changeMetaDataDispatch: changeMetaData,
        updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
        emptyDetailDispatch: emptyDetailAction,
        updateOnPageDescriptionActionDispatch: updateOnPageDescriptionAction,
        contentDetailsFetchCompletedActionDispatch: contentDetailsFetchCompletedAction,
        purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
        showNonAirtelUserAction,
        fetchParticularEpisodeFetchActionDispatch: fetchParticularEpisodeFetchAction,
        seriesDetailsAndTabFetchActionDispatch: seriesDetailsAndTabFetchAction,
    },
)(withWatchlistDataHOC(withDeviceUtil(BannerContentRailContainer))));

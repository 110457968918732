import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    checkWindowExist, getCurrentUrl, getElementById,
} from '@airtel-tv/utils/WindowUtil';
import { connect } from 'react-redux';
import { showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { playbackDetailsFetchAction, playbackWithoutLoginDetailsFetchAction, appInAppHandling } from '@airtel-feature/playback/actions/PlaybackActions';
import {
    getDateWithTitle, getPrefferedPartnerList, readStitchId, updateLionsgateCPTrailerURL, getTrailerPlaybackDetails,
    getAmazonAppInAppMeta,
} from '@airtel-tv/utils/GlobalUtil';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { contentDetailsEvent, unlockNowClickEvent, clickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { SubscriptionUtil } from '@airtel-tv/utils/SubscriptionUtil';
import {
    IMAGE_PATHS, APPLICATION_DOMAINS, MODAL_POPUP, ELEMENT_ID, LOCAL_STORE_KEYS, PROGRAM_TYPES,
    CDP_CTA_TYPES, ONEHUB_INGRESS_INTENT,
} from '@airtel-tv/constants/GlobalConst';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import lodashGet from 'lodash/get';
import { SUBSCRIPTION_SOURCE, LOGIN_SOURCE, EVENT_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import EventType from '@airtel-tv/analytics/EventType';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ThumborUtil } from '@airtel-tv/utils/ThumborUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import BannerDesktopBottomComponent from '../BannerComponent/DesktopBannerBottomComponent';
import DesktopBannerComponent from '../BannerComponent/DesktopBannerComponent';
import MobileBannerBottomCompnent from '../MobileBannerComponent/MobileBannerBottomComponent';
import { hideBannerOnPlay, showBannerOnPlay } from '../../../../../web/src/modules/notify/NotifyActions';
import { showNonAirtelUserAction, updatePreferredPartnerSelections } from '../../../../../web/src/modules/user/UserAction';
import MobileBannerCompnent from '../MobileBannerComponent/MobileBannerComponent';
import BannerWrapperComponent from './BannerWrapperComponent';


const BannerContentContainer = (props) => {
    const {
        playbackDetails,
        showModalComponentActionDispatch,
        playbackDetailsFetchDispatch,
        playbackWithoutLoginDetailsFetchActionDispatch,
        updatePreferredPartnerSelectionDispatch,
        hideBannerOnPlayDispatch,
        showBannerOnPlayDispatch,
        bannerActions,
        isSSRData,
        matchStartTime,
        contentDetails,
        deviceUtil,
        matchTimerOffset,
        isXppUser,
        maxAllowedPlaybackTime,
        userContentProperties,
        showNonAirtelUserActionDispatch
    } = props;

    const [
        playTrailer,
        setPlayTrailer,
    ] = useState(false);

    const updatePlayTrailerState = (val) => {
        setPlayTrailer(val);
    };

    const [
        isUserSubscribed,
        setUserSubscribed,
    ] = useState(false);

    const [
        contentType,
        setContentType,
    ] = useState();

    // const isMobile =

    let { railCtaText = '', railCtaType = '' } = bannerActions || {};
    railCtaText = !isSSRData ? railCtaText : '';

    const [
        statePlaybackDetails,
        setStatePlaybackDetails,
    ] = useState(playbackDetails);

    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const { VIEW_INFO = 'View Info', BUTTON_SWITCH_TO_AIRTEL = 'Switch To Airtel' } = LANGUAGE;

    const {
        WEBVIEW_CTA_TYPE, PLAY_CTA, LOGIN_CTA, ACTIVATE_CTA, POPUP,
    } = CDP_CTA_TYPES;
    const mouseMoveTimerRef = useRef(null);
    useEffect(() => {
        const { contentDetails, subscriptionDataById, cpDetailsById } = props;
        const cpId = lodashGet(contentDetails, 'cpId', '');
        const channelId = SubscriptionUtil.getChannelId({
            cpDetailsById,
            cpId,
        });
        const channelDetails = lodashGet(cpDetailsById, `[${channelId}]`, null);
        const subscriptionDetails = lodashGet(subscriptionDataById, `[${channelId}]`, null);
        const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
        const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);
        console.log('is User subscribed', isSubscribed);
        setUserSubscribed(isSubscribed);

        const {
            contentDetails: {
                trailers,
            },
        } = props;
        let trailerRefId = null;
        let trailerUrl = null;
        if (!checkWindowExist()) {
            return;
        }

        trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;
        trailerUrl = Array.isArray(trailers) && trailers[0]?.trailerUrl;
        if (trailerRefId) {
            playbackWithoutLoginDetailsFetchActionDispatch({
                isTrailer: true,
                contentId: trailerRefId,
            });
        }
        else if (trailerRefId) {
            const newTrailerURl = updateLionsgateCPTrailerURL(trailerUrl, cpId);
            const playbackDetails = getTrailerPlaybackDetails(trailerRefId, newTrailerURl);
            setStatePlaybackDetails({ playbackDetails });
        }
    }, []);

    useEffect(() => () => clearTimeout(mouseMoveTimerRef.current), []);

    useEffect(() => {
        const {
            contentDetails: {
                trailers,
            },
        } = props;
        const trailerRefId = (Array.isArray(trailers) && trailers[0]?.refId) || '';

        if (Object.keys(playbackDetails).length && (trailerRefId !== '' && !playbackDetails[trailerRefId]?.error)) {
            const {
                contentDetails: {
                    trailers, cpId, playableId, id,
                }, isAuthenticated, playbackDetails,
            } = props;
            const trailerRefId = Array.isArray(trailers) && trailers[0]?.refId;
            const trailerUrl = playbackDetails[trailerRefId]?.playback?.playUrl ? playbackDetails[trailerRefId]?.playback?.playUrl : Array.isArray(trailers) && trailers[0]?.trailerUrl;
            if (trailerRefId) {
                const newTrailerURl = updateLionsgateCPTrailerURL(trailerUrl, cpId);
                const playbackDetailslocal = getTrailerPlaybackDetails(trailerRefId, newTrailerURl);
                if (playbackDetails[trailerRefId]?.drm) {
                    playbackDetailslocal[trailerRefId].drm = playbackDetails[trailerRefId].drm;
                    playbackDetailslocal[trailerRefId].playback = playbackDetails[trailerRefId].playback;
                }
                playbackDetailslocal[trailerRefId].mspCp = playbackDetails[trailerRefId]?.mspCp ? playbackDetails[trailerRefId].mspCp : false;
                setStatePlaybackDetails(playbackDetailslocal);
            }
        }
    }, [
        playbackDetails,
    ]);


    const playContent = () => {
        const cdpBanner = getElementById('mobile-top-name-wrapper');
        if (cdpBanner) {
            const playerContainer = document.getElementsByClassName('mobile-player-container')[0];
            if (playerContainer) {
                playerContainer.classList.add('opaque');
            }
        }
    };

    const getUserSessionId = () => {
        const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META) || {};
        const { userSessionId = '' } = analyticsMeta;
        return userSessionId;
    };

    const showXmpClaimPopUp = () => {
        return false;
        const { preferredPartnerPlans, location } = props;
        const { isSubscribed } = isUserSubscribed;
        const PREFFERED_PARTNER_LIST = getPrefferedPartnerList(preferredPartnerPlans);
        const { contentDetails } = props;

        if (!isSubscribed && preferredPartnerPlans && preferredPartnerPlans.length && PREFFERED_PARTNER_LIST.includes(contentDetails.cpId)) {
            const contentUrl = location.pathname;
            // showModalComponentActionDispatch({
            //     showModalValue: true,
            //     componentNameValue: MODAL_POPUP.XMP_CLAIM_MODAL_POPUP,
            //     overrideCrossButtonValue: true,
            //     payload: {
            //         contentDetails,
            //         contentUrl,
            //         origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE_XMP_MODAL,
            //     },
            // });
            const {
                preferredPartnerPlans,
                contentDetails, circle, sid, customerType, uid,
            } = props;

            const cpName = lodashGet(contentDetails, 'cpId', '');
            const selectedOffer = preferredPartnerPlans && preferredPartnerPlans[0] && preferredPartnerPlans[0].offers.filter(offer => offer.cp === cpName);
            const selectedProductId = selectedOffer && selectedOffer.length && selectedOffer[0].productId;
            const referenceId = lodashGet(preferredPartnerPlans[0], 'refId', null);
            const preferredPartnerOfferPlanId = lodashGet(preferredPartnerPlans[0], 'planId', null);
            const preferredPartners = {
                planId: preferredPartnerOfferPlanId,
                products: [
                    selectedProductId,
                ],
                referenceId,
            };
            updatePreferredPartnerSelectionDispatch({
                preferredPartners,
                fromContentDetail: true,
            });
            const pageLoadAnalyticsMeta = {
                pageUrl: getCurrentUrl(),
                appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
                circle,
                sid,
                active_tab: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                source: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                ingressIntent: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                pageName: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
                service: ANALYTICS_ASSETS.AIRTELTV,
                customer_type: customerType,
                uid,
            };
            unlockNowClickEvent(pageLoadAnalyticsMeta, true);
            return true;
        }
        return false;
    };


    const railCtaClicked = async ({
        railCtaText, railCtaType, appInAppRedirection,
    }) => {
        const {
            isAuthenticated,
            triggerLoginDispatch,
            location,
            history,
            navigate,
            contentCollection,
            sid,
            customerType,
            NavigateToPaymentPage,
            bannerActions,
            playbackDetailsFetchDispatch,
            appInAppHandlingDispatch,
            contentDetails: {
                cpId,
                id,
                title,
                programType,
                tvShowName,
                contentAutoplay = false,
                playableId = '',
                gtiId,
            },
            amazonRedirectionConfig,
            planClaimStatusClient,
        } = props;

        const userSessionId = getUserSessionId();
        const isEpisode = programType === PROGRAM_TYPES.EPISODE;
        const isTvShow = programType === PROGRAM_TYPES.TVSHOW;

        const stitchId = readStitchId();
        const subscriptionsMeta = {
            action: `${railCtaText} click`,
            asset_name: railCtaText,
            ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
            source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
            content_name: isEpisode ? tvShowName : title,
            content_id: id,
            cp_name: cpId,
            sid,
            user_session_id: userSessionId,
            playback_stitch_key: stitchId,
        };

        if (!isAuthenticated || railCtaType === LOGIN_CTA) {
            clickEvent({
                ...subscriptionsMeta,
                asset_name: LOGIN_SOURCE.LOGIN_TO_WATCH,
            });
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.PLAY_CLICK,
            });
        }

        else if (railCtaType === WEBVIEW_CTA_TYPE) {
            const { action = {} } = bannerActions || {};
            let parsedUrl;
            let isWebDeeplink;
            if (action?.url) {
                parsedUrl = new URL(action?.url);
                const { hostname } = parsedUrl;
                isWebDeeplink = Object.values(APPLICATION_DOMAINS || []).includes(hostname);
            }

            contentDetailsEvent(subscriptionsMeta, EventType.CLICK);
            if (customerType === 'NON_AIRTEL') {
                navigate(`${RoutingUtil.getSubscriptionPage()}`,
                    {
                        state: {
                            origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                            cpId,
                            ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                            contentUrl: location.pathname,
                        },
                    });
            }
            else if (parsedUrl && isWebDeeplink) {
                const { pathname = '/', search = '' } = parsedUrl || {};
                const { showModalComponentActionDispatch, uid } = props;
                if (pathname.includes('/xpp-claim')) {
                    showModalComponentActionDispatch({
                        showModalValue: true,
                        componentNameValue: MODAL_POPUP.XPP_CLAIM_MODAL,
                        overrideCrossButtonValue: false,
                        payload: {
                            popupVisibleMeta: {
                                asset_name: 'xpp_claim_popup',
                                source_name: 'content_detail_page',
                            },
                            backGroundCustomClass: '',
                            popUpcustomClass: '',
                            uid,
                            showLottie: false,
                            closeBtnClickedHandler: () => {},
                        },

                    });
                    return;
                }
                navigate(`${pathname}${search}`,
                    {
                        state: {
                            origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                            cpId,
                            ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                            contentUrl: location.pathname,
                        },
                    });
            }
            else {
                NavigateToPaymentPage({ cpId });
                return;
            }
        }
        else if (railCtaType === ACTIVATE_CTA) {
            const pendingActivationCps = planClaimStatusClient?.PENDING_ACTIVATION;
            const cpPendingActivation = (pendingActivationCps || []).filter(item => (item?.partner?.name || '').toUpperCase() === cpId.toUpperCase());
            if (cpPendingActivation.length) {
                const link = cpPendingActivation.length > 1 ? RoutingUtil.getOneHubPageCDP({
                    ingressIntent: ONEHUB_INGRESS_INTENT.ACTIVATE,
                    cpId: null,
                }) : RoutingUtil.getCPActivationPage({ cpId: cpPendingActivation?.[0]?.partner?.cpId });
                history.push({
                    pathname: link,
                });
            }
        }
        else if (railCtaType === PLAY_CTA) {
            if (cpId === 'AMAZON_PRIME') {
                const appInAppMeta = getAmazonAppInAppMeta({
                    gtiId,
                    ...amazonRedirectionConfig,
                });
                appInAppHandlingDispatch({
                    appInAppMeta,
                    contentId: id,
                    cpId,
                    appInstalled: false,
                    gtiId,
                });
                return;
            }
            if (appInAppRedirection) {
                playbackDetailsFetchDispatch({
                    contentId: playableId,
                    appInAppRedirection,
                    cpId,
                    appInAppParams: isTvShow ? {
                        ingressIntent: 'watch_now',
                        tvShowId: id,
                    } : {},
                });
                return;
            }
            if (!showXmpClaimPopUp()) {
                const meta = {
                    action: `${railCtaText} click`,
                    source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
                    content_name: isEpisode ? tvShowName : title,
                    content_id: id,
                    cp_name: cpId,
                    user_session_id: userSessionId,
                    playback_stitch_key: stitchId,
                    asset_name: railCtaText,
                };

                if (isEpisode) {
                    meta.episode_name = title;
                }

                contentDetailsEvent(meta, EventType.CLICK);
                const { currentTab } = contentCollection;
                if (contentAutoplay) {
                    const videoNode = getElementById(ELEMENT_ID.TOGGLE_FULLSCREEN);
                    if (videoNode) {
                        videoNode.click();
                    }
                }
                else if (!location.search.includes('showPlayback')) {
                    let params = {
                        showPlayback: true,
                    };
                    if (deviceUtil.isScopedWebview()) {
                        params = {
                            ...params,
                            viewType: 'scoped_webview',
                        };
                    }
                    if (currentTab) {
                        history.replace({
                            pathname: `${location.pathname}`,
                            search: `${LocationUtil.objectToQueryParams({ currentTab })}`,
                        });
                        params.currentTab = currentTab;
                    }
                    // history.push({ pathname: `${location.pathname}?${LocationUtil.objectToQueryParams(params)}` });
                    navigate(`${location.pathname}?${LocationUtil.objectToQueryParams(params)}`);
                }
            }
            else {
                contentDetailsEvent(subscriptionsMeta, EventType.XMP_CLICK);
            }
        }
        else if (railCtaType === POPUP) {
            if(railCtaText?.toLowerCase() === BUTTON_SWITCH_TO_AIRTEL?.toLowerCase()) {
                showNonAirtelUserActionDispatch({ cpId })
            }
        }
    };


    const showTimer = ({ matchStartTime = 0, matchTimerOffset = 0 }) => {
        const currentTime = Date.now();
        const startDiff = matchStartTime - currentTime;
        return (matchTimerOffset > startDiff && startDiff > 0);
    };

    const getLanguage = () => {
        const { languageMap = [], contentDetails: { languages = [] } } = props;
        const langArray = [];
        if (languageMap.length === 0) {
            return [];
        }
        languageMap?.forEach((lang) => {
            if (languages && languages.includes(lang.lan)) {
                langArray.push(lang.n);
            }
        });

        return langArray;
    };

    const primaryLanguage = getLanguage()?.[0] || '';


    const getAirTime = ({ matchStartTime = 0, matchStartTimeOffset = 0 }) => {
        const currentTime = Date.now();
        const startTime = getShowTime(matchStartTime);
        const curDate = new Date().getDate();
        const matchDate = new Date(matchStartTime).getDate();
        const matchMonth = new Date(matchStartTime).getMonth();
        const matchYear = new Date(matchStartTime).getFullYear();
        let dayOfMatch = 'Today';
        const noOfDaysInCurMonth = new Date(matchYear, matchMonth, 0).getDate();
        if ((noOfDaysInCurMonth == curDate && matchDate == 1) || (curDate + 1) === matchDate) {
            dayOfMatch = 'Tomorrow';
        }
        if (matchStartTimeOffset > matchStartTime - currentTime > 0) {
            return (
                <p className="live-tag-player start-reminder-tag">
                    {dayOfMatch}
                    {' '}
                    at
                    {' '}
                    { startTime.toUpperCase() }
                </p>
            );
        }
    };

    const airTime = matchStartTime && railCtaText ? getAirTime({
        contentAutoplay,
        matchStartTime,
        matchStartTimeOffset,
    }) : null;

    const matchStartTimer = ({ matchStartTime = 0, matchTimerOffset = 0 }) => {
        if (matchStartTime && showTimer({
            matchStartTime,
            matchTimerOffset,
        })) {
            return (
                <p className="starting-timer">
                    {/* place your lottie file here */}
                    <Lottie
                        loop={1}
                        animationData={timeAnimation}
                        play
                    />
                    <span className="start-text">Starting in</span>
                    <span className="timer"><Timer airTime={matchStartTime} /></span>
                </p>
            );
        }
        return null;
    };


    const getPlaybackType = () => {
        const { playbackDetails, contentDetails: { id = '' } } = props;
        const { CDP_TRAILER = 'Trailer' } = LANGUAGE;

        if (id && playbackDetails[id]?.success && contentType === '') {
            if (playbackDetails && playbackDetails[id]?.contentType === PROGRAM_TYPES.TRAILER) {
                setContentType({ contentType: CDP_TRAILER });
            }
            else if (!true && playbackDetails[id]?.contentPlayTag) {
                // else if (!this.isMobile && playbackDetails[id]?.contentPlayTag) {
                setContentType({ contentType: playbackDetails[id]?.contentPlayTag });
            }
            else {
                setContentType({ contentType: playbackDetails[id]?.contentPlayTag || playbackDetails[id]?.playbackType });
            }
        }
        return playbackDetails[id]?.success || false;
    };

    const upcomingTextValue = (reminderTime) => {
        const { UPCOMING = 'Coming Soon', COMING_ON = 'Coming on' } = LANGUAGE;
        if (reminderTime) {
            return `${COMING_ON} ${getDateWithTitle(reminderTime, 'short', true)}`;
        }
        return UPCOMING;
    };

    const {
        upcoming, images, id, contentAutoplay, reminderTime,
    } = contentDetails;

    const upcomingDate = upcoming ? upcomingTextValue(reminderTime) : '';

    const showTeaserTag = (contentAutoplay && getPlaybackType() && contentType !== '');
    const showLiveTag = id && props.playbackDetails[id]?.success && props.playbackDetails[id]?.playbackType === 'LIVE' || (matchStartTime && Date.now() > matchStartTime);

    const getImage = ({ images, isMobile }) => {
        if (isMobile) {
            return images?.SQUARE || images?.SQUARE_HD || images?.PORTRAIT || images?.PORTRAIT_HD || images?.LANDSCAPE_169 || '';
        }
        return images?.LANDSCAPE_169 || IMAGE_PATHS.DEFAULT_PLAYER_BACKGROUND;
    };

    const isCSRResponse = checkWindowExist();

    let mobileImageUrl = getImage({
        images,
        isMobile: true,
    });

    let desktopImageUrl = getImage({
        images,
        isMobile: false,
    });

    mobileImageUrl = ThumborUtil.getThumborUrl(500, 0, mobileImageUrl, deviceUtil.getImageFormat(mobileImageUrl));
    desktopImageUrl = ThumborUtil.getThumborUrl(1600, 0, desktopImageUrl, deviceUtil.getImageFormat(desktopImageUrl));

    if (!isCSRResponse) {
        mobileImageUrl = `preloadImage${mobileImageUrl}preloadImage`;
        desktopImageUrl = `preloadImage${desktopImageUrl}preloadImage`;
    }

    const headerSetTimeout = () => {
        clearTimeout(mouseMoveTimerRef.current);
        mouseMoveTimerRef.current = setTimeout(() => {
            if (document.querySelector('header')?.contains(document.activeElement)) {
                clearTimeout(mouseMoveTimerRef.current);
            }
            else {
                headerToogle('hide');
            }
        }, 2000);
    };

    const headerToogle = (state) => {
        if (state === 'show') {
            if (mouseMoveTimerRef.current) {
                clearTimeout(mouseMoveTimerRef.current);
            }
            document.querySelector('header')?.classList.add('show-header');
            document.querySelector('header')?.classList.remove('hide-header');
        }
        else if (state === 'hide') {
            document.querySelector('header')?.classList.add('hide-header');
            document.querySelector('header')?.classList.remove('show-header');
        }
        else if (state === 'alwaysOn') {
            if (mouseMoveTimerRef.current) {
                clearTimeout(mouseMoveTimerRef.current);
            }
            document.querySelector('header')?.classList.remove('hide-header');
            if (document.querySelector('header')) {
                document.querySelector('header').style.marginTop = '0px';
            }
        }
    };

    const mouseMoveCallBack = () => {
        if (!playTrailer || document.querySelector('header')?.classList.contains('fixed')) {
            clearTimeout(mouseMoveTimerRef.current);
            headerToogle('alwaysOn');
            return;
        }
        headerToogle('show');
        headerSetTimeout();
    };

    const openPopUp = () => {
        const { showModalComponentActionDispatch } = props;
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.MORE_INFO_POPUP,
            overrideCrossButtonValue: true,
            payload: {
                ...props,
            },

        });
    };

    // const canPlayPlayback = () => {
    //     const {
    //         maxAllowedPlaybackTime,
    //         isNonAirtelUser
    //     } = props;
    //     const maxAllowedPlaybackTimeInSecs = maxAllowedPlaybackTime * 60;

    //     const totalPlaybackTime = ScopedWebviewUtil.getPlaybackTime();
    //     if (!deviceUtil.isScopedWebview() || isNonAirtelUser) {
    //         return true;
    //     }
    //     if (deviceUtil.isIOS()) {
    //         return false;
    //     }
    //     if (isXppUser) {
    //         return maxAllowedPlaybackTimeInSecs > totalPlaybackTime;
    //     }
    //     return contentDetails?.free ? maxAllowedPlaybackTimeInSecs > totalPlaybackTime : false;
    // };

    return (
        <BannerWrapperComponent
            {...props}
            {...bannerActions}
            mouseMoveCallBack={isCSRResponse && !deviceUtil.isMobileViewport() && mouseMoveCallBack || null}
            isPlayable={playTrailer}
            playContent={playContent}
            playbackDetails={statePlaybackDetails}
            railCtaText={railCtaText}
            railCtaClicked={railCtaClicked}
            showTimer={showTimer}
            isSubscribed={isUserSubscribed}
            primaryLanguage={primaryLanguage}
            airTime={airTime}
            matchStartTimer={() => (railCtaText ? matchStartTimer({
                matchStartTime,
                matchTimerOffset,
            }) : null)}
            showTeaserTag={showTeaserTag}
            contentType={contentType}
            showLiveTag={showLiveTag}
            upcomingDate={upcomingDate}
            mobileImageUrl={mobileImageUrl}
            desktopImageUrl={desktopImageUrl}
            isXppUser={isXppUser}
            openPopUp={openPopUp}
            canPlayPlayback={() => ScopedWebviewUtil.canPlayPlayback(maxAllowedPlaybackTime, userContentProperties, contentDetails)}
            mobileBottomCtaJSX={isCSRResponse && (deviceUtil.isMobileViewport() && !deviceUtil.isScopedWebview() && (
                <MobileBannerBottomCompnent
                    railCtaText={railCtaText}
                    {...props}
                />
            )) || null}
            desktopBottomCtaJSX={isCSRResponse && (!deviceUtil.isMobileViewport() && (
                <BannerDesktopBottomComponent
                    railCtaClicked={railCtaClicked}
                    railCtaText={railCtaText}
                    VIEW_INFO={VIEW_INFO}
                    {...props}
                />
            ) || null)}
        >
            {isCSRResponse && (deviceUtil.isMobileViewport() && (
                <MobileBannerCompnent
                    {...props}
                    {...bannerActions}
                    updatePlayTrailerState={updatePlayTrailerState}
                    isPlayable={playTrailer}
                    playContent={playContent}
                    playbackDetails={statePlaybackDetails}
                    railCtaText={railCtaText}
                    railCtaClicked={railCtaClicked}
                    showTimer={showTimer}
                    isSubscribed={isUserSubscribed}
                    primaryLanguage={primaryLanguage}
                    airTime={airTime}
                    matchStartTimer={() => (railCtaText ? matchStartTimer({
                        matchStartTime,
                        matchTimerOffset,
                    }) : null)}
                    showTeaserTag={showTeaserTag}
                    contentType={contentType}
                    showLiveTag={showLiveTag}
                    upcomingDate={upcomingDate}
                />
            ) || (
                <DesktopBannerComponent
                    {...props}
                    {...bannerActions}
                    updatePlayTrailerState={updatePlayTrailerState}
                    playTrailer={playTrailer}
                />
            ))}
        </BannerWrapperComponent>
    );
};


BannerContentContainer.propTypes = {
    tiles: PropTypes.array.isRequired,
    showPlayback: PropTypes.bool.isRequired,
    contentId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    showPlaybackDispatch: PropTypes.func.isRequired,
    packageResetDispatch: PropTypes.func.isRequired,
    seriesDetailsFetchActionDispatch: PropTypes.func.isRequired,
    blogConfig: PropTypes.object,
    cdpPostFooterMapping: PropTypes.object,
    bannerActions: PropTypes.object,
    NavigateToPaymentPage: PropTypes.func,
    trailerRefId: PropTypes.string,
    amazonRedirectionConfig: PropTypes.object.isRequired,
    planClaimStatusClient: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
    const {
        userConfig: {
            preferredPartnerPlans = null, uid = '', subscriptionDataById, customerType = '', userContentProperties, userContentProperties: { oSeg, ut },
        } = {},
        authConfig,
        playbackDetails,
        appConfig,
        appConfig: { amazonRedirectionConfig = {} },
        planConfig: { sid = '', userDetails: { circle = '' } = {} } = {},
        modalConfig,
        layoutDetails,
        notify: { playingOnBanner },
        plansAndSubscriptionData: {
            svodPlans: {
                all_plans: allPlans = {},
            } = {},
        } = {},
    } = state;
    const { planClaimStatusClient = {} } = allPlans;
    const { maxAllowedPlaybackTime = 20 } = appConfig;

    const { pageId } = props;
    const { layoutPackages = [], isSSRData = false } = layoutDetails?.[pageId] || {};
    const { matchTimerOffset = 1000 * 60 * 60 * 24, matchStartTimeOffset = 1000 * 60 * 60 * 24 } = appConfig;
    const { loginTriggered } = authConfig;
    const isXppUser = !!(oSeg || '').split(',').filter(item => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;
    const isNonAirtelUser = (ut || '').toLowerCase() === 'nonairtel';

    return {
        isAuthenticated: authConfig.isAuthenticated,
        preferredPartnerPlans,
        playbackDetails,
        subscriptionDataById,
        cpDetailsById: appConfig.cpDetailsById,
        uid,
        sid,
        circle,
        customerType,
        modalConfig,
        layoutPackages,
        playingOnBanner,
        matchTimerOffset,
        matchStartTimeOffset,
        isSSRData,
        languageMap: appConfig.language_map_web,
        loginTriggered,
        amazonRedirectionConfig,
        maxAllowedPlaybackTime,
        isXppUser,
        isNonAirtelUser,
        userContentProperties,
        planClaimStatusClient,
    };
}

export default connect(mapStateToProps, {
    showModalComponentActionDispatch: showModalComponentAction,
    playbackDetailsFetchDispatch: playbackDetailsFetchAction,
    playbackWithoutLoginDetailsFetchActionDispatch: playbackWithoutLoginDetailsFetchAction,
    updatePreferredPartnerSelectionDispatch: updatePreferredPartnerSelections,
    hideBannerOnPlayDispatch: hideBannerOnPlay,
    showBannerOnPlayDispatch: showBannerOnPlay,
    appInAppHandlingDispatch: appInAppHandling,
    showNonAirtelUserActionDispatch: showNonAirtelUserAction,
})(withDeviceUtil(withRouter(BannerContentContainer)));

import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ResizeHOC } from '@airtel-tv/lib/hoc/ResizeHOC';
import { withWatchlistDataHOC } from '@airtel-tv/lib/hoc/withWatchlistDataHOC';
import { connect } from 'react-redux';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { showModalComponentAction, hideModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import { triggerLogin } from '@airtel-feature/auth/AuthAction';
import { showPlayback } from '@airtel-feature/notify/NotifyActions';
import {
    DEEPLINK_INGRESS_KEYS, ELEMENT_ID, MODAL_POPUP, PROGRAM_TYPES,
} from '@airtel-tv/constants/GlobalConst';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { getAudioLanguages } from '@airtel-tv/utils/GlobalUtil';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';
import { RAIL_TYPES } from '@airtel-tv/constants';
import ActivateChannelPartnerRail from '@airtel-tv/ui-lib/molecules/rail/ActivateChannelPartnerRail/ActivateChannelPartnerRail';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import BannerWidgetComponent from '../BannerWidgetComponent/BannerWidgetComponent';
import { contentDetailsFetchAction } from '../../../content-details/actions/ContentDetailsAction';
import styles from './BannerWrapperComponent.scss';
import XWarningRailComponent from '../../../../../web/src/modules/rail/components/XWarningRailComponent/XWarningRailComponent';


const BannerWrapperComponent = (props) => {
    const LANGUAGE = LanguageProviderUtil.getLanguage();

    const {
        CDP_TRAILER = 'Trailer', IMDB = 'IMDb', SEASONS = 'Seasons', AUDIO_AVAILABLE_IN = 'Audio Available in:', CDP_MWEB_TRAILERS = 'Trailers', CDP_MWEB_SHARE = 'Share', CDP_MWEB_MORE = 'more', WATCHLIST = 'Watchlist', GO_FULLSCREEN = 'Watch in Fullscreen',
        FREE_TAG_TEXT = 'Free', WEBVIEW_CTA_TYPE = 'WEBVIEW', ACTIVATION_IN_PROGRESS_CTA = 'Activation in Progress',
    } = LANGUAGE;
    const {
        imageSettings, title, deviceUtil, pageId, contentDetails, railSecondaryText, railPrimaryText, contentDetails: {
            genres = [], ageRating = '', shortUrl = '', languages, description = '', shareShortUrl = '', playableId = '', id = '', seriesTvSeasons: seasons,
            free = false,
        }, history, watchListToggleButtonClick, continueWatchingData, playbackDetails,
        railCtaClicked, showCtaText = true, showTimer, pageRefresh,
        airTime, matchStartTimeOffset, matchStartTimer, showTeaserTag, contentType, showLiveTag,
        upcomingDate,
        railCtaText: railCtaTextFromProps,
        railCtaType,
        imageUrl,
        location,
        primaryLanguage = '',
        matchRoute,
        isSubscribed,
        loginTriggered,
        modalConfig,
        mobileImageUrl,
        desktopImageUrl,
        isPlayable,
        mouseMoveCallBack,
        bannerActions,
        openPopUp,
        layoutPackages: localLayoutPackages = [],
        contentId,
        freeTagEnabled = false,
        bannerActions: { partnerActivationRail = null } = {},
        showModalComponentActionDispatch,
        hideModalComponentActionDispatch,
        canPlayPlayback,
        isXppUser,
        deepLinkConfig,
        claimedCps,
        claimInProgressCps,
    } = props;

    const [
        railCtaTextComputed,
        setCtaText,
    ] = useState('');

    const [
        disableRailCta,
        setDisableRailCta,
    ] = useState(false);

    const isXWarningRail = localLayoutPackages.find(item => item.format.ty === RAIL_TYPES.X_WARNING_RAIL) || {};
    // const { railPrimaryText = '' } = partnerActivationRail || {};

    // check whether we need useState for showing railCtaText

    const {
        shouldAddInWL = true, contentAutoplay = false, matchStartTime = null, programType = '', releaseYear = '',
        appInAppRedirection = false,
    } = contentDetails;

    const showFreeTag = freeTagEnabled && programType === PROGRAM_TYPES.MOVIE && free;

    const conditionalProps = mouseMoveCallBack ? { onMouseMove: mouseMoveCallBack } : {};

    const isCSRResponse = checkWindowExist();

    const openMorePopupMeta = {
        action: ANALYTICS_ACTIONS.RAIL_CTA_CLICK,
        asset_name: ANALYTICS_ASSETS.MORE,
        source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
    };

    const showCtaButton = () => {
        const { contentAutoplay = false, programType } = contentDetails;
        const { isSubscribed } = props;
        if ((!contentAutoplay || (contentAutoplay && !isSubscribed))) {
            return true;
        }
        return false;
    };

    const showBanner = () => {
        const contentPlaybackDetatils = playbackDetails?.[contentId]?.playback;
        if (contentAutoplay) {
            if (Object.keys(contentPlaybackDetatils || {}).length) { // contentAutoPlay and we don't have playbackdetails
                return false;
            }
        }
        else if (isPlayable) { // Content is not autoplay but isPlayable true
            return false;
        }
        return true;
    };

    useEffect(() => {
        const { contentCollection, match: { path, params } } = props;
        const { PLAY_CTA = 'PLAY', RESUME_CTA_WITH_EPISODENUM = 'Resume: S{episodeSeasonNum} E{episodeNum}', RESUME_CTA = 'Resume' } = LANGUAGE;
        let railCta = railCtaTextFromProps;

        const contentIdCW = (
            contentDetails.programType === PROGRAM_TYPES.TVSHOW
            || contentDetails.programType === PROGRAM_TYPES.MOVIE || contentDetails.programType === PROGRAM_TYPES.VIDEO
                ? contentDetails.id
                : contentDetails.seriesId
        );

        const { episodeId } = params;
        const isEpisodeRoute = matchRoute(ROUTE_PATHS.TV_EPISODE_PLAYBACK, location.pathname) !== null;
        if (isSubscribed && railCtaType === PLAY_CTA && continueWatchingData && continueWatchingData[contentIdCW] && Object.keys(continueWatchingData[contentIdCW]).length > 0) {
            const { contentResponse: { episodeSeasonNum, episodeNum, playableId } } = continueWatchingData[contentIdCW];
            if (isEpisodeRoute || matchRoute(ROUTE_PATHS.PLAYBACK, location.pathname) !== null) {
                railCta = episodeSeasonNum && episodeNum ? RESUME_CTA_WITH_EPISODENUM.replace(/{episodeSeasonNum}/g, episodeSeasonNum)
                    .replace(/{episodeNum}/g, episodeNum) : RESUME_CTA;
            }
            else if (isEpisodeRoute && ((playableId === episodeId) || (playableId === contentCollection?.[episodeId]?.playableId))) {
                railCta = RESUME_CTA;
            }
        }
        if (!claimedCps?.length && claimInProgressCps?.length) {
            railCta = ACTIVATION_IN_PROGRESS_CTA;
            setDisableRailCta(true);
        }
        setCtaText(railCta);

    }, [
        railCtaType,
        isSubscribed,
        railCtaTextFromProps,
        contentDetails,
        LANGUAGE,
        continueWatchingData,
        claimedCps,
        claimInProgressCps
    ]);


    return (
        <>
            <div
                className="cdp-banner-container"
                {...conditionalProps}
            >
                {isCSRResponse && (
                    <div
                        className="back-btn scroll-top-name-wrapper"
                        id="mobile-top-name-wrapper"
                    >
                        <AnalyticsButtonComponent
                            onClick={() => history.go(-1)}
                            aria-label="Go to previous Page"
                            className="m-banner-back"
                        >
                            <svg
                                width="9"
                                height="16"
                                viewBox="0 0 9 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.75 14.875L0.875 8L7.75 1.125"
                                    stroke="#E8EAED"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </AnalyticsButtonComponent>
                        <p>{ title }</p>
                    </div>
                )}

                <div className="top-gredient">
                    <AnalyticsButtonComponent
                        className="m-banner-back non-header"
                        onClick={() => history.go(-1)}
                        aria-label="Go to previous Page"
                    >
                        <svg
                            width="9"
                            height="16"
                            viewBox="0 0 9 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.75 14.875L0.875 8L7.75 1.125"
                                stroke="#E8EAED"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </AnalyticsButtonComponent>
                </div>

                {/* Render player and trailer part here */}

                {props?.children}

                {/* -------------- */}

                <div
                    className="banner-detail-container"
                    id="banner-details-container"
                >
                    <div className="left-shade" />
                    {showBanner() && (
                        <>
                            <div className="banner-img-wrapper mobile-img">
                                <img
                                    className="cdp-banner-image"
                                    src={mobileImageUrl}
                                />
                            </div>
                            <div className="banner-img-wrapper desktop-img">
                                <img
                                    className="cdp-banner-image"
                                    src={desktopImageUrl}
                                />
                                <div className="bottom-shade" />
                            </div>
                        </>
                    )
                    }
                    {matchStartTime && matchStartTimer()}
                    <div className={`cdp-detail ${isPlayable ? 'trailer-autoplay' : ''} ${contentAutoplay ? 'content-autoplay' : ''}`}>
                        <div className="content-details">
                            <h1
                                id={ELEMENT_ID.BANNER_CONTENT_TITLE}
                                className="cdp-title"
                            >
                                { title }
                            </h1>
                            <div className="cdp-tags">
                                {ageRating && <span className="age-rating-tag">{ ageRating }</span> }
                                {showFreeTag ? <span className="free-cdp-tag text-14 text-bolder line-height-16 mobile-text-12 mobile-text-semi-bold">{FREE_TAG_TEXT}</span> : ''}
                                {upcomingDate !== '' && <span className="m-web-tags outer coming-soon-text">{ upcomingDate }</span>}
                                {/* RATING TO BE ADDED WHEN DATA WILL BE AVAILABLE FROM BACKEND  */}
                                {/* <span className="m-web-tags outer imdb">IMDb 6.6</span> */}

                                {/* use one at a time fromm blow elements */}

                                {airTime || null }
                                {showLiveTag ? <p className="live-tag-player">Live</p> : null}

                                {/* un comment above only one at time */}
                                { genres.length > 0 && (
                                    <span
                                        className="m-web-tags outer"
                                        id={ELEMENT_ID.BANNER_CONTENT_GENRE}
                                    >
                                        { genres.map(genre => (
                                            <span className="m-web-tags inner">{ genre }</span>
                                        ))}
                                    </span>
                                ) }
                                { seasons && seasons.length > 1 && (
                                    <p className="m-web-tags inner">
                                        { seasons.length }
                                        {' '}
                                        {SEASONS}
                                    </p>
                                ) }
                                { primaryLanguage && (
                                    <p
                                        className="m-web-tags outer"
                                        id={ELEMENT_ID.BANNER_CONTENT_LANGUAGE}
                                    >
                                        { primaryLanguage }
                                    </p>
                                ) }
                                <p
                                    className="m-web-tags inner"
                                    id={ELEMENT_ID.BANNER_RELEASE_YEAR}
                                >
                                    { releaseYear }
                                </p>
                                <p
                                    className="cdp-desc"
                                    id={ELEMENT_ID.BANNER_CONTENT_DESCRIPTION}
                                >
                                    { description }
                                </p>
                                { languages && languages.length > 0 && (
                                    <p
                                        className="audio-description"
                                        id={ELEMENT_ID.BANNER_AUDIO_LANGUAGES}
                                    >
                                        {AUDIO_AVAILABLE_IN}
                                        {' '}
                                        {getAudioLanguages(props)}
                                    </p>
                                ) }
                                {Object.keys(isXWarningRail).length ? <section className="desktop-x-warning-rail"><XWarningRailComponent {...isXWarningRail} /></section> : ''}
                                <>
                                    {railSecondaryText && (
                                        <p
                                            className="plan-price"
                                            id={ELEMENT_ID.PLAN_DESCRIPTION}
                                            dangerouslySetInnerHTML={{ __html: railSecondaryText }}
                                        />
                                    )}
                                </>
                                <>
                                    {
                                        railPrimaryText
                                            ? (
                                                <p
                                                    className="top-header-activate text-16 line-height-24 text-bold"
                                                    dangerouslySetInnerHTML={{ __html: railPrimaryText }}
                                                />
                                            )
                                            : ''
                                    }
                                </>
                                <div className="view-btn-align-wrapper">
                                    <div className="view-btn-align">
                                        <AnalyticsButtonComponent
                                            className={`cdp-watch-btn  ${disableRailCta ? 'fade-disable-state' : ''} ${railCtaTextFromProps ? '' : 'shimmer'} ${contentAutoplay ? 'hide-cta' : ''}`}
                                            onClick={() => {
                                                if (disableRailCta) {
                                                    return;
                                                }
                                                if (deviceUtil.isScopedWebview() && (!canPlayPlayback() || (railCtaType === WEBVIEW_CTA_TYPE))) {
                                                    showModalComponentActionDispatch({
                                                        showModalValue: true,
                                                        componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                                                        overrideCrossButtonValue: true,
                                                        showBackButton: true,
                                                        payload: {
                                                            contentDetails,
                                                            isSubscribed: isXppUser,
                                                            pageId,
                                                            meta: {
                                                                source_name: 'mweb_landing',
                                                                popup_id: 'download_hard_popup',
                                                                content_category: 'Content',
                                                            },
                                                            notNowBtnHandler: () => {
                                                                hideModalComponentActionDispatch({
                                                                    showModalValue: false,
                                                                    componentNameValue: null,
                                                                    overrideCrossButtonValue: false,
                                                                });
                                                            },
                                                            downloadNowBtnHandler: () => {
                                                                ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                                                            },
                                                        },
                                                    });
                                                    return;
                                                }
                                                railCtaClicked({
                                                    railCtaText: railCtaTextFromProps,
                                                    railCtaType,
                                                    appInAppRedirection,
                                                });
                                            }}
                                            id={ELEMENT_ID.PLAY_CONTENT_BUTTON}
                                            description="PLAY CONTENT BUTTON"
                                        >
                                            {railCtaTextFromProps
                                                ? (
                                                    <>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M21.3889 11.36L7.8911 3.11102C7.77741 3.04154 7.64726 3.0036 7.51404 3.00111C7.38083 2.99861 7.24935 3.03165 7.13314 3.09683C7.01692 3.162 6.92017 3.25696 6.85283 3.37193C6.7855 3.4869 6.75 3.61774 6.75 3.75098V20.2488C6.75 20.3821 6.7855 20.5129 6.85283 20.6279C6.92017 20.7429 7.01692 20.8378 7.13314 20.903C7.24935 20.9682 7.38083 21.0012 7.51404 20.9987C7.64726 20.9962 7.77741 20.9583 7.8911 20.8888L21.3889 12.6399C21.4985 12.5729 21.5891 12.4788 21.652 12.3668C21.7148 12.2547 21.7478 12.1284 21.7478 11.9999C21.7478 11.8714 21.7148 11.7451 21.652 11.633C21.5891 11.521 21.4985 11.427 21.3889 11.36Z"
                                                                fill="#0C0F12"
                                                            />
                                                        </svg>
                                                    </>
                                                ) : null }
                                            { contentAutoplay && isSubscribed ? GO_FULLSCREEN : railCtaTextComputed}

                                        </AnalyticsButtonComponent>
                                        {/* for desktop view widget ctas */}
                                        {(isCSRResponse && !deviceUtil.isMobileViewport() || !isCSRResponse) && (
                                            <div className="desktop-widget-cta">
                                                <BannerWidgetComponent
                                                    {...props}
                                                    {...bannerActions}
                                                    railCtaText={props.railCtaText || ''}
                                                    railCtaClicked={railCtaClicked}
                                                />
                                            </div>
                                        ) }
                                    </div>
                                    {props.desktopBottomCtaJSX}
                                </div>
                                {partnerActivationRail ? <ActivateChannelPartnerRail {...partnerActivationRail} /> : ''}
                                {isXWarningRail && Object.keys(isXWarningRail).length > 0 ? <section className="mobile-x-warning-rail"><XWarningRailComponent {...isXWarningRail} /></section> : ''}

                                {railSecondaryText ? (
                                    <p
                                        className="mweb-plan-info-detail"
                                        id={ELEMENT_ID.MOBILE_PLAN_DESCRIPTION}
                                        dangerouslySetInnerHTML={{ __html: railSecondaryText }}
                                    />
                                ) : ''}
                            </div>
                        </div>
                    </div>

                </div>
                {/* This is for mobile */}
                {!checkWindowExist() && <div className="banner-bottom-detail-container mobile-shimmer" />}
            </div>
            {props.mobileBottomCtaJSX}
            {/* Mobile Description  */}
            {(isCSRResponse && deviceUtil.isMobileViewport() || !isCSRResponse)
                ? (
                    <div
                        className="m-web-desc"
                        style={deviceUtil.isScopedWebview() ? { marginTop: '30px' } : {}}
                    >
                        {description && (
                            <p
                                className="desc"
                                id={ELEMENT_ID.MOBILE_BANNER_CONTENT_DESCRIPTION}
                            >
                                <span className="short-desc">
                                    { description.slice(0, 125) }
                                    ...
                                </span>
                                <AnalyticsButtonComponent
                                    className="more-button"
                                    onClick={openPopUp}
                                    meta={openMorePopupMeta}
                                >
                                    { CDP_MWEB_MORE }
                                </AnalyticsButtonComponent>

                            </p>
                        ) }

                        { languages && languages.length > 0 ? (
                            <p
                                className="information-mobile"
                                id={ELEMENT_ID.MOBILE_BANNER_AUDIO_LANGUAGES}
                            >
                                {AUDIO_AVAILABLE_IN}
                                {' '}
                                <span>{getAudioLanguages(props, openPopUp)}</span>
                            </p>
                        ) : ''}
                    </div>
                ) : ''
            }
        </>
    );
};

BannerWrapperComponent.propTypes = {
    mouseMoveCallBack: PropTypes.func,
    isPlayable: PropTypes.bool,
    playContent: PropTypes.func,
    playbackDetails: PropTypes.object,
    railCtaText: PropTypes.string,
    railCtaClicked: PropTypes.func,
    showTimer: PropTypes.func,
    isSubscribed: PropTypes.bool,
    primaryLanguage: PropTypes.string,
    airTime: PropTypes.func,
    matchStartTimer: PropTypes.func,
    showTeaserTag: PropTypes.bool,
    contentType: PropTypes.object,
    showLiveTag: PropTypes.bool,
    upcomingDate: PropTypes.string,
    mobileImageUrl: PropTypes.string,
    desktopImageUrl: PropTypes.string,
    openPopUp: PropTypes.func,
    bannerActions: PropTypes.object,

};

BannerWrapperComponent.defaultProps = {
    bannerActions: {},
};

const mapStateToProps = (state, props) => {
    const {
        appConfig, userConfig, layoutDetails, themeConfig: { configs }, packageContents,
        authConfig: { isAuthenticated, loginTriggered },
        syncContent: {
            watchList,
            continueWatchingData,
        },
        modalConfig,
        playbackDetails,
        plansAndSubscriptionData: {
            svodPlans: {
                all_plans: allPlans = {},
            } = {},
        } = {},
    } = state;

    const { planClaimStatusClient = {} } = allPlans;
    const {
        cpDetailsById, sharableContent, freeTagEnabled = false, deepLinkConfig,
    } = appConfig;
    const { subscriptionDataById } = userConfig;
    // const pageId = lodashGet(props, 'match.params.pageId', '');
    const { pageId, cpId } = props;
    const layoutPackages = lodashGet(layoutDetails[pageId], 'layoutPackages', []);
    const canFetchMore = lodashGet(layoutDetails, `[${pageId}].fetchMore`, false);
    const totalRailsFetched = lodashGet(layoutDetails, `[${pageId}].totalFetched`, 0);
    const layoutError = lodashGet(layoutDetails[pageId], 'error', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', null);
    const { matchStartTimeOffset = 86400000 } = appConfig;
    const claimedCps = planClaimStatusClient?.CLAIMED?.filter(item => item?.partner?.name === cpId) || [];
    const claimInProgressCps = planClaimStatusClient?.CLAIM_IN_PROGRESS?.filter(item => item?.partner?.name === cpId) || [];
    return {
        languageMap: appConfig.language_map,
        userInfo: userConfig.userInfo,
        pageId,
        layoutPackages,
        layoutError,
        cpDetailsById,
        themeConfig: configs,
        packageContents,
        subscriptionDataById,
        isAuthenticated,
        canFetchMore,
        totalRailsFetched,
        watchList,
        continueWatchingData,
        preferredPartnerPlans,
        sharableContent,
        matchStartTimeOffset,
        loginTriggered,
        modalConfig,
        playbackDetails,
        freeTagEnabled,
        deepLinkConfig,
        claimedCps,
        claimInProgressCps,
    };
};

export default withDeviceUtil(connect(mapStateToProps,
    {
        showModalComponentActionDispatch: showModalComponentAction,
        hideModalComponentActionDispatch: hideModalComponentAction,
        triggerLoginDispatch: triggerLogin,
        showPlaybackDispatch: showPlayback,
        contentDetailsFetchDispatch: contentDetailsFetchAction,
    })(withWatchlistDataHOC(ResizeHOC(withRouter(withStyles(styles)(BannerWrapperComponent))))));
